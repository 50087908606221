import React from "react";
import { useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation();
  React.useEffect(() => {
    if (location.pathname.includes("&snpcht")) {
      window.location.href = "/Exercise";
    } else if (location.pathname.includes("&bsky")) {
      window.location.href = "/Exercise";
    } else if (location.pathname.includes("&instgrm")) {
      window.location.href = "/Exercise";
    } else if (location.pathname.includes("&thrds")) {
      window.location.href = "/Exercise";
    } else if (location.pathname.includes("&tkt")) {
      window.location.href = "/Exercise";
    }
  }, [location]);
  let startDate = new Date().toISOString().substring(0, 4);
  return (
    <footer className="footer mt-4">
      <div className="position-fixed bottom-len left-0 mb-5 ui tertiary icon button" style={{ zIndex: "150" }} onClick={() => { document.getElementById("navbar").scrollIntoView(); }}>
      <i className="bi bi-arrow-up-square-fill" style={{fontSize:"30px"}}></i></div>
      <div className="container text-center py-3">
        <p>&copy;{startDate} Built using React.js | By Roy</p>
      </div>
    </footer>
  );
};

export default Footer;
