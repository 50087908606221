import React from "react";

const AddExercise = ({Elist, AddWorkout, selectItem,collectionUser,index }) => {

  return (
    <div className="ui middle aligned divided list scrl-on">
    {Elist
      .map((a, idx) => (
        <div key={idx} className="item pe-on rc-drops">
          <div className="left floated content mx-0 px-0">
            <div className="ui tertiary icon button" onClick={() => index >=0 ? AddWorkout(a, collectionUser.exercise_sets, index) : alert(`This muscle is not on your list, add it first.`)}>
              <i className="bi bi-plus-circle fs-4"></i>
            </div>
          </div>
          <div className="content pe-on m-0" onClick={() => selectItem(a.name)}>
          <div className="header text-truncate">
            {a.name}
          </div>
          <div className="description text-truncate">
            {a.primaryMuscles.join(", ")} {a.secondaryMuscles.join(", ")}
          </div>
        </div>
        </div>
      ))}
  </div>
  );
};

export default AddExercise;
