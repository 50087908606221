export const generateRandomString = (length) => {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let randomString = "";
  for (let i = 0; i < length; i++) {
    randomString += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return randomString;
};
export const scope = [
  "ugc-image-upload",
  "user-read-playback-state",
  "user-modify-playback-state",
  "user-read-currently-playing",
  "app-remote-control",
  "streaming",
  "playlist-read-private",
  "playlist-read-collaborative",
  "playlist-modify-private",
  "playlist-modify-public",
  "user-follow-modify",
  "user-follow-read",
  "user-read-playback-position",
  "user-top-read",
  "user-read-recently-played",
  "user-library-modify",
  "user-library-read",
  "user-read-email",
  "user-read-private",
].join(" ");
export const Spotifylogin = () => {
  const scope = [
    "ugc-image-upload",
    "user-read-playback-state",
    "user-modify-playback-state",
    "user-read-currently-playing",
    "app-remote-control",
    "streaming",
    "playlist-read-private",
    "playlist-read-collaborative",
    "playlist-modify-private",
    "playlist-modify-public",
    "user-follow-modify",
    "user-follow-read",
    "user-read-playback-position",
    "user-top-read",
    "user-read-recently-played",
    "user-library-modify",
    "user-library-read",
    "user-read-email",
    "user-read-private",
  ].join(" ");
  const authUrl = `https://accounts.spotify.com/authorize?response_type=code&client_id=0a9995dd76d44757a722335e07ca7844&scope=${scope}&redirect_uri=https://royclaudio.com/Services&state=${generateRandomString(16)}`;
  window.location.replace(authUrl);
};
async function fetchWebApi(endpoint, method, body, access_token) {
  const response = await fetch(`https://api.spotify.com/v1/${endpoint}`, {
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    method,
    body: body ? JSON.stringify(body) : null,
  });

  if (!response.ok) {
    const errorResponse = await response.json().catch(() => ({}));
    return {
      error: "API_ERROR",
      message: `Spotify API responded with status ${response.status}`,
      details: errorResponse,
      status: response.status,
    };
  }
  const data = await response.json();
  return data;
}
export const fetchTracks = async (totalTracks, token, query) => {
  const tracks = [];
  const limit = 50;
  for (let offset = 0; offset < totalTracks; offset += limit) {
    const response = await fetchWebApi(query + `limit=${limit}&offset=${offset}`, "GET", token);
    tracks.push(...response.items);
  }
  return tracks;
};
// const fetchArtists = async (artistIds, token) => {
//   const artists = [];
//   const limit = 50;
//   const artistSet = new Set();
//   for (let offset = 0; offset < artistIds.length; offset += limit) {
//     const idsBatch = artistIds.slice(offset, offset + limit).join(",");
//     const response = await fetchWebApi(`artists?ids=${idsBatch}`, "GET", token);
//     response.artists.forEach((artist) => {
//       if (!artistSet.has(artist.id)) {
//         artistSet.add(artist.id);
//         artists.push(artist);
//       }
//     });
//   }
//   return artists;
// };
export const getTracks = async (length, User) => {
  if (!User || Date.now() > Number(User.expires_in)) 
    throw new Error("User key expired or invalid");
  let query = "";
  if (length) {
    query = `me/top/tracks?time_range=${length}&`;
  } else {
    query = "me/tracks?";
  }
  const userTracksResponse = await fetchWebApi(
    query + "&limit=1&offset=0", "GET", User.access_token);
  const totalTracks = userTracksResponse.total;
  const tracks = await fetchTracks(totalTracks, User.access_token, query);
  // const trackIds = tracks.map((track) => track.track.id);
  // const uniqueArtistIds = [...new Set(tracks.flatMap((track) => track.track.artists.map((artist) => artist.id).filter((id) => id)))];
  // const audioFeatures = await fetchAudioFeatures(trackIds, User.access_token);
  // const artists = await fetchArtists(uniqueArtistIds, User.access_token);
  // const finalTracks = buildTracksData(tracks, audioFeatures, userId, artists);
  // setCollection(tracks);
  return tracks;
};
export const saveSongs = async (User) => {
  let saveCollection = [];//collection.map((track) => "spotify:track:" + track.id);
  let season = ((date) => `${["Winter", "Spring", "Summer", "Fall"][(((new Date(date).getMonth() + 1) % 12) / 3) | 0]} ${new Date().getFullYear()}`)(new Date().toISOString());
  var name = `${season}`;
  var answer = window.confirm(`Are you sure you want to save: ${name}?`);
  var playlistDescription = `Playlist created on royclaudio.com for ${season}`;
  const res1 = await fetchWebApi(`users/${User.id}/playlists`, "POST", { name: name, description: playlistDescription }, User.access_token);
  let offset = 0;
  let limit = 50;
  if (answer) {
    while (offset < saveCollection.length) {
      let uriArray = saveCollection.slice(offset, offset + limit);
      await fetchWebApi(`playlists/${res1.id}/tracks`, "POST", { uris: uriArray }, User.access_token);
      offset += limit;
    }
    const res3 = await fetchWebApi(`playlists/${res1.id}`, "GET", null, User.access_token);
    //setCollection3(res3);
    console.log(res3);
  }
};