import React from "react";
import "../../assets/styles/Blog.css";

const Placeholder = () => {
    return (
        <div className="ui three column stackable relaxed grid">
        <div className="column">
        <div className="ui raised centered card p-5 mb-3">
            <div className="ui placeholder">
              <div className="image header">
                <div className="line"></div>
                <div className="line"></div>
              </div>
              <div className="paragraph">
                <div className="line"></div>
                <div className="line"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="column">
          <div className="ui raised centered card p-5 mb-3">
            <div className="ui placeholder">
              <div className="image header">
                <div className="line"></div>
                <div className="line"></div>
              </div>
              <div className="paragraph">
                <div className="line"></div>
                <div className="line"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="column">
          <div className="ui raised centered card p-5">
            <div className="ui placeholder">
              <div className="image header">
                <div className="line"></div>
                <div className="line"></div>
              </div>
              <div className="paragraph">
                <div className="line"></div>
                <div className="line"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default Placeholder;
