export const HelperAccordion = (event) => {
  let elem = event.currentTarget;
  let child = elem.nextElementSibling;
  let ielem = elem.querySelector(".angle.right.icon");
  let ielem1 = elem.querySelector(".angle.down.icon");
  if (ielem) {
    ielem.classList.remove("right");
    ielem.classList.add("down");
  } else if (ielem1) {
    ielem1.classList.remove("down");
    ielem1.classList.add("right");
  }
  if (elem.classList.contains("active")) {
    elem.classList.remove("active");
    child.classList.remove("active");
  } else {
    elem.classList.add("active");
    child.classList.add("active");
  }
};
export function OpenTab(id, type) {
  const collapseElement = document.getElementById(`${id}-tab-pane`);
  const buttonElement = document.getElementById(`${id}-tab`);
  const myTab = document.getElementById(`${type}-myTab`);
  const myTabContent = document.getElementById(`${type}-myTabContent`);
  if (collapseElement.classList.contains("show")) {
    collapseElement.classList.remove("show");
    collapseElement.classList.remove("active");
    buttonElement.classList.remove("active");
  } else {
    if (myTabContent) {
      const childrenTabs = myTab.querySelectorAll("*");
      const childrenTabContent = myTabContent.querySelectorAll("*");
      childrenTabs.forEach((child) => {
        child.classList.remove("show");
        child.classList.remove("active");
      });
      childrenTabContent.forEach((child) => {
        child.classList.remove("show");
        child.classList.remove("active");
      });
      collapseElement.classList.add("show");
      collapseElement.classList.add("active");
      buttonElement.classList.add("active");
    }
  }
}
