import React, { useState, useMemo, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import remarkSupersub from "remark-supersub";
import axios from "axios";
import "../assets/styles/Blog.css";
import Modal from "react-bootstrap/Modal";
import Placeholder from "../components/Allpages/PlaceHolder";
import { useNavigate } from "react-router-dom";

class CommentProgram {
  constructor(PostID, UserID, Body) {
    this.PostID = PostID;
    this.UserID = UserID;
    this.Body = Body;
    let msec = Date.now();
    let startDate = new Date(msec).toISOString();
    this.CreatedAt = startDate;
    this.UpdatedAt = startDate;
  }
}
class LikeProgram {
  constructor(PostID, UserID) {
    this.PostID = PostID;
    this.UserID = UserID;
    let msec = Date.now();
    let startDate = new Date(msec).toISOString();
    this.CreatedAt = startDate;
  }
}
const Articles = () => {
  const [techContent, setContent] = useState([]);
  const [selectedObj, setSelectedObj] = useState({});
  const [show, setShow] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [isBusy, setIsBusy] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    const GetItems = async () => {
      let markdownFiles = require.context(`../assets/markdown/`, false, /\.md$/);
      const filePromises = markdownFiles.keys().map((filePath) => {
        return import(`../assets/markdown/${filePath.replace("./", "")}`)
          .then((module) => ({
            fileName: filePath,
            content: module.default,
          }))
          .catch((error) => {
            console.error("Error importing Markdown file:", filePath, error);
            return null;
          });
      });

      Promise.all(filePromises).then((results) => {
        const fetchPromises = results
          .filter((result) => result !== null)
          .map((result) => {
            return fetch(result.content)
              .then((response) => response.text())
              .then((text) => ({
                Image: text.split("\n")[2],
                Title: text.split("\n")[0].replace("# ", "").trim(),
                Filename: result.fileName.replace("./", "").replace(".md", ""),
                Byline: text.split("\n")[6].substring(0, 90).trim(),
                Content: text.split("\n").slice(3).join("\n"),
              }))
              .catch((error) => {
                console.error("Error fetching Markdown file:", result.content, error);
                return null;
              });
          });

        Promise.all(fetchPromises).then(async (contentMap) => {
          const validContent = contentMap.filter((item) => item !== null);
          validContent.sort((a, b) => (a.Filename > b.Filename ? -1 : 1));
          let data = [];
          try {
            const response = await axios.get(`/GetPosts`);
            data = response.data;
          } catch (error) {
            const response = await axios.get(`assets/tempblogs.json`);
            data = response.data;
            console.log("Whoops, couldn't fetch Updated Posts. Probably on localhost, or the server is down.");
          }
          const contentWithPostData = validContent.map((content) => {
            const post = data.find((post) => post.PostFileName === content.Filename + ".md");
            if (post) {
              return {
                ...content,
                PostID: post.PostID || 0,
                PostFileName: post.PostFileName || "",
                CreatedAt: post.CreatedAt || "2024-09-09 22:56:12",
                UpdatedAt: post.UpdatedAt || "2024-09-09 22:56:12",
                Views: post.Views || 0,
                Likes: post.Likes || [],
                Comments: post.Comments || "",
              };
            } else {
              return {};
            }
          });
          setContent(contentWithPostData);
        });
      });
      setIsBusy(false);
    };
    GetItems();
  }, []);
  const AddComment = async () => {
    const UserID = document.getElementById("name").value;
    const comment = document.getElementById("comment").value;
    const form = document.getElementById("commentForm");
    if (!UserID || !comment) {
      alert("All fields are required!");
      return;
    }
    const user = new CommentProgram(selectedObj.PostID, UserID, comment);
    try {
      const response = await axios.post(`/comment/${user.UserID}/${user.PostID}/${user.Body}`);
      if (response.status === 200) {
        form.reset();
      }
      localStorage.setItem("CommentProgram", JSON.stringify(user));
      setShow(false);
      alert("Comment Sent and tied to your current browser, Thank you!");
    } catch (error) {
      alert("Whoops, couldn't send comment. Probably on localhost, or the server is down.");
    }
  };
  const AddLike = async () => {
    try {
      const getip = await axios.get(`https://api.ipify.org?format=json`);
      let UserID = ipToLetter(getip.data.ip);
      const user = new LikeProgram(selectedObj.PostID, UserID);
      const response = await axios.get(`/like/${user.UserID}/${user.PostID}`);
      localStorage.setItem("LikeProgram", JSON.stringify(user));
      selectedObj.Likes = response.data.Likes;
      selectedObj.Views = response.data.Views;
      setIsLiked(true);
      alert(user.UserID + " liked this post, Thank you!");
    } catch (error) {
      alert("Whoops, couldn't send like. Probably on localhost, or the server is down.");
    }
  };
  function ipToLetter(ip) {
    function numberToBinaryString(num, bits) {
      return num.toString(2).padStart(bits, "0");
    }
    function binaryToLetters(binary) {
      let num = parseInt(binary, 2);
      let letter = String.fromCharCode((num % 26) + 65);
      return letter;
    }
    if (ip.includes(".")) {
      let octets = ip.split(".");
      return octets
        .map((octet) => {
          let binary = numberToBinaryString(parseInt(octet), 8);
          return binaryToLetters(binary);
        })
        .join("");
    } else if (ip.includes(":")) {
      let hextets = ip.split(":").map((group) => group || "0"); // Handle empty groups
      return hextets
        .map((hextet) => {
          let binary = numberToBinaryString(parseInt(hextet, 16), 16);
          return binaryToLetters(binary);
        })
        .join("");
    } else {
      throw new Error("Invalid IP address format");
    }
  }
  const SelectObj = async (obj) => {
    setSelectedObj(obj);
    try {
      await axios.post(`/views/${++obj.Views}/${obj.PostID}`);
    } catch (error) {
      console.log("Whoops, couldn't update views. Probably on localhost, or the server is down.");
    }
  };
  const blogCards = useMemo(
    () =>
      techContent
        .sort((a, b) => (a.PostID > b.PostID ? -1 : 1))
        .map((item, idx) => (
          <div key={idx} className="ui blue link centered card" onClick={() => navigate(`/ArticleView/${item.PostID}`)}>
            <img className="ui Small image post" src={item.Image.split("](")[1]?.replace(")", "")} alt={`${item.Title}`} />
            <div className="content">
              <div>
                <div className="right floated">
                  <div className="ui teal label">{item.Filename}</div>
                </div>
                <div className="ui sub header mt-0" style={{ height: "32px" }}>
                  {item.Title}
                </div>
              </div>
              <div>
                <div className="right floated">
                  <div className="ui teal labels">
                    <span className="ui label">
                      <i className="eye outline icon"></i>
                      {item.Views}
                    </span>
                    <span className="ui label">
                      <i className="heart outline icon"></i>
                      {item.Likes.length}
                    </span>
                    <span className="ui label">
                      <i className="comment dots outline icon"></i>
                      {item.Comments.length}
                    </span>
                  </div>
                </div>
                <div className="meta">{timeSince(item.CreatedAt)}</div>
              </div>
              <div className="description">{item.Byline}</div>
            </div>
          </div>
        )),
    [techContent, navigate]
  );
  function ToDateString(dateString) {
    const options = { year: "numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric", hour12: true };
    const date = new Date(dateString);
    return date.toLocaleString("en-US", options);
  }
  function timeSince(CreatedAt) {
    const now = new Date();
    const createdDate = new Date(CreatedAt);
    const seconds = Math.floor((now - createdDate) / 1000);
    const intervals = {
      years: 31536000,
      months: 2592000,
      weeks: 604800,
      days: 86400,
      hours: 3600,
      minutes: 60,
      seconds: 1,
    };

    let timeString = "";

    for (const interval in intervals) {
      const diff = Math.floor(seconds / intervals[interval]);
      if (diff >= 1) {
        timeString = `${diff} ${interval} ago`;
        break;
      }
    }

    return timeString || "just now";
  }
  if (!techContent) {
    return <div>Loading...</div>;
  }
  return (
    <div id="mainroot" className="ui container">
      {selectedObj.Title && (
        <div className="ui breadcrumb">
          <button className="ui tertiary blue button" onClick={() => setSelectedObj({})}>
            Home
          </button>
          <span className="divider">/</span>
          <button className="ui tertiary button disabled">{selectedObj.Title}</button>
        </div>
      )}
      {selectedObj.PostID ? (
        <div>
          <div className="slides">
            <div className="ui list article-list">
              {techContent.map((blog, idx) => (
                <div key={idx} className="item pe-on" onClick={() => SelectObj(blog)} style={{ minWidth: "250px" }}>
                  <img className="ui avatar image blog" src={blog.Image.split("](")[1]?.replace(")", "")} alt={`${blog.Title} track cover`} />
                  <div className="content">
                    <div className="ui sub header text-truncate">{blog.Title}</div>
                    <div className="description text-truncate">{blog.Byline}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <img className="ui centered medium bordered image mt-3" src={selectedObj.Image.split("](")[1]?.replace(")", "")} alt={`${selectedObj.Title}`} />
          <div className="markdown-content mt-2">
            <div className="ui middle aligned divided list">
              <div className="item">
                <div className="right floated content">
                  <div className="ui teal labels">
                    <span className="ui big label">
                      <i className="eye outline icon"></i>
                      {selectedObj.Views}
                    </span>
                    <span
                      className="ui label pe-on"
                      onClick={() => {
                        isLiked ? alert("You Already Liked This Post!") : AddLike();
                      }}
                    >
                      {isLiked ? <i className="heart icon"></i> : <i className="heart outline icon"></i>}
                      {selectedObj.Likes.length}
                    </span>
                    <span className="ui label pe-on" onClick={() => setShow(true)}>
                      <i className="bi bi-chat-square-dots"></i>&nbsp;
                      {selectedObj.Comments.length}
                    </span>
                  </div>
                </div>
                <div className="content">
                  <div className="meta">
                    <i className="bi bi-person"></i>&nbsp; By Roy {ToDateString(selectedObj.CreatedAt)}
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="content">
                  <ReactMarkdown remarkPlugins={[remarkSupersub]}>{selectedObj.Content}</ReactMarkdown>
                </div>
              </div>
              <div className="item">
                <div className="content">
                  <div className="ui feed">
                    {selectedObj.Likes.map((like, idx) => (
                      <div key={idx} className="event">
                        <div className="content">
                          <div className="summary">
                            <span className="user ui teal text">{like.UserID}</span> <span className="ui purple text">liked this post</span>
                            <div className="date">
                              <span className="ui teal text">{ToDateString(like.CreatedAt)}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    {selectedObj.Comments.map((comment, idx) => (
                      <div key={idx} className="event">
                        <div className="content">
                          <div className="summary">
                            <span className="user ui teal text">{comment.UserID}</span> <span className="ui purple text">commented</span>
                            <div className="date">
                              <span className="ui teal text">{ToDateString(comment.CreatedAt)}</span>
                            </div>
                          </div>
                          <div className="extra text">{comment.Body}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : isBusy ? (
        <Placeholder />
      ) : (
        <div className="ui stackable cards">{blogCards}</div>
      )}
      <Modal id="modal" size="lg" show={show} aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Comment on {selectedObj.Title ?? ""}</Modal.Title>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setShow(false);
            }}
          ></button>
        </Modal.Header>
        <Modal.Body>
          <form className="ui form" id="commentForm">
            <div className="ui form">
              <div className="field">
                <span className="ui teal text">Name</span>
                <input type="text" style={{ fontSize: "16px" }} className="form-control" id="name" placeholder="Name" />
              </div>
            </div>
            <div className="field">
              <span className="ui teal text">Comment</span>
              <textarea style={{ fontSize: "16px" }} className="form-control" id="comment" placeholder="Add comment..."></textarea>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="ui black button" onClick={() => setShow(false)}>
            Cancel
          </button>
          <button type="button" className="ui icon red button" onClick={() => AddComment()}>
            <i className="bi bi-arrow-up-square-fill"></i> &nbsp; Send
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Articles;
