import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import remarkSupersub from "remark-supersub";
import "../assets/styles/Blog.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const ArticleView = () => {
  const { id } = useParams();
  const [article, setArticle] = useState(null);
  const [techContent, setContent] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const GetItems = async () => {
      let markdownFiles = require.context(`../assets/markdown/`, false, /\.md$/);
      const filePromises = markdownFiles.keys().map((filePath) => {
        return import(`../assets/markdown/${filePath.replace("./", "")}`)
          .then((module) => ({
            fileName: filePath,
            content: module.default,
          }))
          .catch((error) => {
            console.error("Error importing Markdown file:", filePath, error);
            return null;
          });
      });

      Promise.all(filePromises).then((results) => {
        const fetchPromises = results
          .filter((result) => result !== null)
          .map((result) => {
            return fetch(result.content)
              .then((response) => response.text())
              .then((text) => ({
                Image: '../' + text.split("\n")[2].split("](")[1]?.replace(")", "").trim(),
                Title: text.split("\n")[0].replace("# ", "").trim(),
                Filename: result.fileName.replace("./", "").replace(".md", ""),
                Byline: text.split("\n")[6].substring(0, 90).trim(),
                Content: text.split("\n").slice(3).join("\n"),
              }))
              .catch((error) => {
                console.error("Error fetching Markdown file:", result.content, error);
                return null;
              });
          });

        Promise.all(fetchPromises).then(async (contentMap) => {
          const validContent = contentMap.filter((item) => item !== null);
          validContent.sort((a, b) => (a.Filename > b.Filename ? -1 : 1));
          let data = [];
          try {
            const response = await axios.get(`/GetPosts`);
            data = response.data;
          } catch (error) {
            const response = await axios.get(`../assets/tempblogs.json`);
            data = response.data;
            console.log("Whoops, couldn't fetch Updated Posts. Probably on localhost, or the server is down.");
          }
          const contentWithPostData = validContent.map((content) => {
            const post = data.find((post) => post.PostFileName === content.Filename + ".md");
            if (post) {
              return {
                ...content,
                PostID: post.PostID || 0,
                PostFileName: post.PostFileName || "",
                CreatedAt: post.CreatedAt || "2024-09-09 22:56:12",
                UpdatedAt: post.UpdatedAt || "2024-09-09 22:56:12",
                Views: post.Views || 0,
                Likes: post.Likes || [],
                Comments: post.Comments || "",
              };
            } else {
              return {};
            }
          });
          const article = contentWithPostData.find((item) => item.PostID === parseInt(id));
          setArticle(article);
          setContent(contentWithPostData);
        });
      });
    }
    GetItems();
  }, [id]);
  if (!article) {
    return <div>Loading...</div>;
  }
  return (
    <div className="ui container">
      <div className="slides">
        <div className="ui list article-list">
          {techContent.map((blog, idx) => (
            <div key={idx} className="item pe-on" onClick={() =>  navigate(`/ArticleView/${blog.PostID}`)} style={{ minWidth: "250px" }}>
              <img className="ui avatar image blog" src={blog.Image} alt={`${blog.Title} track cover`} />
              <div className="content">
                <div className="ui sub header text-truncate">{blog.Title}</div>
                <div className="description text-truncate">{blog.Byline}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <h1 className="mx-5">{article.Title}</h1>
      <div className="meta mx-5">
        <span>By Roy</span> | <span>{new Date(article.CreatedAt).toLocaleDateString()}</span>
      </div>
      <img className="ui centered image" src={article.Image} alt={article.Title} />
      <div className="markdown-content mt-5">
      <ReactMarkdown remarkPlugins={[remarkSupersub]}>{article.Content}</ReactMarkdown>
      </div>
    </div>
  );
};

export default ArticleView;
