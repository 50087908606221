import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { InputGroup } from "react-bootstrap";

const ProfileInput = ({ saveUser, collectionUser, show, onHide }) => {
  const [formValues, setFormValues] = useState({
    name: collectionUser.name ?? "",
    age: collectionUser.age ?? "",
    weight: collectionUser.weight ?? "",
    reps: collectionUser.frequency_reps ?? "",
    sets: collectionUser.frequency_sets ?? "",
    exercise_sets: collectionUser.exercise_sets ?? "",
  });
  const [formTitle, setFormTitle] = useState('New User');
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  const RecommendedValues = () => {
    setFormValues({
      ...formValues,
      reps: 10,
      sets: 12,
      exercise_sets: 4,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    saveUser(formValues);
  };
  useEffect(() => {
    setFormTitle(collectionUser.name ?? 'New User');
  }, [collectionUser.name]);
  return (
    <Modal show={show} onHide={onHide} backdrop="static" keyboard={false} centered data-bs-theme={window.matchMedia("(prefers-color-scheme: dark)").matches ? 'dark' : 'light'}>
      <Modal.Header closeButton>
        <Modal.Title>{formTitle ?? 'New User'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="Name">
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" autoComplete="off" placeholder="Enter Name" value={formValues.name} name="name" size="40" style={{ fontSize: "16px" }} onChange={(e) => handleInputChange(e)} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="Age">
            <div className="d-flex justify-content-between align-items-center mb-2">
              <div>
                <Form.Label>Age</Form.Label>
              </div>
              <div className="d-flex justify-content-start">
                <Form.Label>Bodyweight</Form.Label>
              </div>
            </div>
            <InputGroup className="mb-3">
              <Form.Control
                type="text"
                placeholder="Enter your age"
                value={formValues.age}
                name="age"
                size="2"
                style={{ fontSize: "16px" }}
                onChange={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                  handleInputChange(e);
                }}
              />
              <Form.Control
                type="text"
                placeholder="Enter your body weight"
                value={formValues.weight}
                name="weight"
                size="3"
                style={{ fontSize: "16px" }}
                onChange={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                  handleInputChange(e);
                }}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3" controlId="Reps">
            <div className="d-flex justify-content-between align-items-center mb-2">
              <div>
                <Form.Label>Reps</Form.Label>
              </div>
              <div className="d-flex justify-content-start">
                <Button variant={`${window.matchMedia("(prefers-color-scheme: dark)").matches ? 'outline-light' : 'outline-secondary'}`} onClick={() =>RecommendedValues()}>
                  BrickXBrick Recommended Values
                </Button>
              </div>
            </div>
            <Form.Control
              type="text"
              placeholder="Enter reps"
              value={formValues.reps}
              name="reps"
              size="2"
              style={{ fontSize: "16px" }}
              onChange={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, "");
                handleInputChange(e);
              }}
            />
            <Form.Text className="text-muted">The goal reps for each exercise.</Form.Text>
          </Form.Group>
          <Form.Group className="mb-3" controlId="SetsPerMuscleGroup">
            <Form.Label>Sets per muscle group</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter sets"
              value={formValues.sets}
              name="sets"
              size="2"
              style={{ fontSize: "16px" }}
              onChange={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, "");
                handleInputChange(e);
              }}
            />
            <Form.Text className="text-muted">(i.e., 12 sets for chest, arms etc. per week.)</Form.Text>
          </Form.Group>
          <Form.Group className="mb-3" controlId="SetsPerExercise">
            <Form.Label>Sets per exercise</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Sets"
              value={formValues.exercise_sets}
              name="exercise_sets"
              size="2"
              style={{ fontSize: "16px" }}
              onChange={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, "");
                handleInputChange(e);
              }}
            />
            <Form.Text className="text-muted">(i.e., 4 sets for bench, squats etc. per week.)</Form.Text>
          </Form.Group>
          <Button type="submit">Submit</Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ProfileInput;
