import React from "react";
import "../../assets/styles/Blog.css";
const SelectedItemComponent = ({ selectedObj, setSelectedObj,audioRef}) => {
  return (
    <div className="ui middle aligned divided list accordion-open-sticky1">
      <div className="item">
        <img className="ui avatar image movie" src={selectedObj.image} alt="movie" />
        <div className="content">
          <div className="right floated content">
            <div className="ui tertiary icon button">
              <i className="bi bi-play-circle"></i>
            </div>
            <div className="ui tertiary icon button" onClick={() => setSelectedObj({})}>
              <i className="close icon"></i>
            </div>
          </div>
          <div className="header"> {selectedObj.name}</div>
          <div>{selectedObj["artists.names"] ?? selectedObj.artists}</div>
          <div className="ui horizontal list m-0 p-0">
            {selectedObj.genres.map((genre, idx) => (
              <div key={idx} className="ui left icon purple label pe-on">
                {genre}
              </div>
            ))}
          </div>
          <div className="description audio">
            {selectedObj.preview_url && (
              <audio controls ref={audioRef} crossOrigin="anonymous">
                <source src={selectedObj.preview_url} type="audio/mpeg" />
              </audio>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectedItemComponent;
