import React from "react";
import BodySvg from "./BodySvg";
const ExerciseView = ({ Elist, selectItem }) => {
  return (
    <div className="ui middle aligned divided list px-2 mt-4">
      {Elist.map((item, idx) => {
        return (
          <div key={idx} className="item rc-drops d-flex flex-row align-items-center pe-on" onClick={() => selectItem(item.name)}>
            <div className="flex-shrink-0">
            <BodySvg primaryMuscles={item.primaryMuscles} secondaryMuscles={item.secondaryMuscles} clss={"bodysvg"} />
            </div>
            <div className="content flex-grow-1 ms-3">
              <div className="header">{item.name}</div>
              <div className="description">
                {item.primaryMuscles} {item.secondaryMuscles.join(", ")}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ExerciseView;
