import React, { useState } from "react";
import { Form, Toast } from "react-bootstrap";

const SetControl = ({ UpdateSet, move, set, UpdateAllSets }) => {
  const [showB, setShowB] = useState(false);
  const [title, setTitle] = useState("");

  const [formValues, setFormValues] = useState({
    reps: set.reps ?? 0,
    weight: set.weight ?? 0,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  const saveButtonOneRef = React.useRef(null);
  const saveButtonTwoRef = React.useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (UpdateSet(move, set, formValues)) {
      toggleShowB("Set");
      const elem = saveButtonOneRef.current;
      elem.innerHTML = `<i class="bi bi-floppy-fill"></i>`;
      setTimeout(() => {
        toggleShowB("Set", true);
        elem.innerHTML = `<i class="bi bi-floppy"></i>`;
      }, 5000);
    }
  };
  const handleSubmit1 = (move, set) => {
    if (UpdateAllSets(move, set)) {
      toggleShowB("All Sets");
      const elem = saveButtonTwoRef.current;
      elem.innerHTML = `<i class="copy icon"></i>`;
      setTimeout(() => {
        toggleShowB("All Sets", true);
        elem.innerHTML = `<i class="copy outline icon"></i>`;
      }, 5000);
    }
  };
  const toggleShowB = (title, close = false) => {
    setTitle(title);
    setShowB(close ? false : !showB);
  };
  return (
    <Form onSubmit={handleSubmit} className="d-flex flex-row align-items-end" data-bs-theme={window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light"}>
      <button className="ui tertiary icon button" onClick={() => handleSubmit1(move, set)} ref={saveButtonTwoRef}>
        <i className="copy outline icon"></i> Copy
      </button>
      <div className="Input-form">
        <span title="Sets">
          <input
            type="phone"
            name="reps"
            id="formReps"
            maxLength="2"
            size="sm"
            className="form-control"
            style={{ fontSize: "16px" }}
            aria-label="Set repetitions"
            placeholder="reps"
            value={formValues.reps === 0 ? "" : formValues.reps}
            onChange={(e) => {
              e.target.value = e.target.value.replace(/[^0-9]/g, "");
              handleInputChange(e);
            }}
          />
        </span>
        <span title="Weight">
          <input
            type="phone"
            name="weight"
            id="formWeight"
            maxLength="3"
            size="sm"
            className="form-control"
            style={{ fontSize: "16px" }}
            aria-label="Set weight"
            placeholder="Weight"
            value={formValues.weight === 0 ? "" : formValues.weight}
            onChange={(e) => {
              e.target.value = e.target.value.replace(/[^0-9]/g, "");
              handleInputChange(e);
            }}
          />
        </span>
      </div>
      <button type="submit" className="ui tertiary icon button" ref={saveButtonOneRef}>
        <i className="bi bi-floppy"></i> Save
      </button>
      <Toast onClose={() => toggleShowB("close")} show={showB} className="position-fixed top-50 left-50 w-50" style={{ zIndex: "25" }} data-bs-theme={window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light"}>
        <Toast.Header>
          <strong className="me-auto">{title} Updated!</strong>
        </Toast.Header>
      </Toast>
    </Form>
  );
};

export default SetControl;
