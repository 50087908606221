import React, { useEffect, useState, useCallback } from "react";
import { Collapse } from "react-bootstrap";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import PagingComponent from "../components/Allpages/PagingComponent";
import SelectedItemComponent from "../components/Allpages/SelectedItemComponent";
import { HelperAccordion, OpenTab } from "../components/Allpages/HelperFunctions";

class movieProgram {
  constructor(name, age) {
    this.name = name;
    this.age = age;
    this.movieFeats = [];
    this.movieList = [];
    let msec = Date.now();
    let startDate = new Date(msec).toISOString();
    this.create_date = startDate;
  }
}
const Movies = () => {
  const [collection, setCollection] = useState([]);
  const [selectedObj, setSelectedObj] = useState({});
  const [filtersArray, setFiltersArray] = useState([]);
  const [currentsArray, setCurrentsArray] = useState([]);
  const [show, setShow] = useState(false);
  const [collectionUser, setUserCollection] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const filterFields = ["Language", "Country", "Rated", "Released", "imdbRating", "Genre", "Runtime"];
  const PerPage = 60;
  const totalPages = Math.ceil((collection?.length || 0) / PerPage);
  const maxPagesToShow = 3;
  useEffect(() => {
    const fields = ["Language", "Country", "Rated", "Released", "imdbRating", "Genre", "Runtime"];
    const GetJson = async () => {
      const response = await axios.get(`assets/movies.json`);
      let data = response.data;
      setCollection(data);
      let Main = [];
      fields.map((element) => {
        switch (element) {
          case "Language":
            Main[element] = [...new Set(data.flatMap((g) => g.Language.split(", ")).filter((g) => g !== null))];
            break;
          case "Country":
            Main[element] = [...new Set(data.flatMap((g) => g.Country.split(", ")).filter((g) => g !== null))];
            break;
          case "Rated":
            Main[element] = [...new Set(data.map((g) => g.Rated).filter((g) => g !== null))];
            break;
          case "Released":
            Main[element] = [
              ...new Set(
                data
                  .map((g) => g.Released.split(" ")[2])
                  .sort()
                  .filter((g) => g !== null && g !== undefined)
              ),
            ];
            break;
          case "imdbRating":
            Main[element] = [...new Set(data.map((g) => Math.floor(Number(g.imdbRating))).filter((g) => g !== null))];
            break;
          case "Genre":
            Main[element] = [
              ...new Set(
                data
                  .flatMap((g) => g.Genre.split(", "))
                  .sort()
                  .filter((g) => g !== null)
              ),
            ];
            break;
          case "Runtime":
            Main[element] = [
              ...new Set(
                data
                  .map((g) => g.Runtime)
                  .sort()
                  .filter((g) => g !== null)
              ),
            ];
            break;
          default:
            return "";
        }
        return "";
      });
      setFiltersArray(Main);
      if (localStorage.getItem("movieProgram")) {
        let user = JSON.parse(localStorage.getItem("movieProgram"));
        setUserCollection(user);
      }
    };
    GetJson();
  }, []);
  const handlePageChange = (page) => {
    setCurrentPage(page);
    window.scrollTo(0, 0);
  };
  const handleReverse = useCallback(() => {
    setCollection((prevCollection) => [...prevCollection].reverse());
    setSelectedObj({});
    setCurrentPage(1);
    window.scrollTo(0, 0);
  }, []);
  const goTo = useCallback((Link) => {
    window.location.href = Link;
  }, []);
  const handleCollectionChange = (option, item) => {
    setCurrentsArray((prevSelected) => {
      const updateList = { ...prevSelected };
      if (item === "") {
        updateList[option] = [];
      } else {
        if (prevSelected[option] && prevSelected[option].includes(item)) {
          updateList[option] = prevSelected[option].filter((g) => g !== item);
        } else {
          updateList[option] = [...(prevSelected[option] || []), item];
        }
      }
      return updateList;
    });
  };
  const updateCollection = useCallback((items, current) => {
    return items.filter((elem) => {
      return Object.entries(current).every(([key, values]) => {
        if (key === "imdbRating") {
          return values.length === 0 || values.includes(Math.floor(Number(elem[key])));
        } else if (key === "Released") {
          return values.length === 0 || values.includes(elem[key].split(" ")[2]);
        } else if (key === "Genre") {
          return values.length === 0 || values.some((a) => elem[key].split(", ").some((b) => a === b));
        } else {
          return values.length === 0 || values.includes(elem[key]);
        }
      });
    });
  }, []);
  useEffect(() => {
    const fetchAndUpdateCollection = async () => {
      try {
        const response = await axios.get(`assets/movies.json`);
        const data = response.data;
        let updatedList = updateCollection(data, currentsArray);
        setCollection(updatedList);
      } catch (error) {
        console.error("Error fetching JSON:", error);
      }
    };
    fetchAndUpdateCollection();
  }, [currentsArray, updateCollection, collectionUser]);
  const myRef = React.useRef(null);
  function saveUser() {
    const name = document.getElementById("name").value;
    const age = document.getElementById("age").value;
    if (!name || !age) {
      alert("All fields are required!");
      return;
    }
    if (isNaN(age) || age <= 0) {
      alert("Please enter a valid age.");
      return;
    }
    const mp = new movieProgram(name, Number(age));
    localStorage.setItem("movieProgram", JSON.stringify(mp));
    setUserCollection(mp);
    setShow(false);
    alert("Movie Profile saved successfully!");
  }
  function updateUser(obj) {
    localStorage.setItem("movieProgram", JSON.stringify(obj));
    setUserCollection(obj);
  }
  const DeleteObj = (Obj) => {
    const updatedCollectionUser = { ...collectionUser };
    updatedCollectionUser.movieList = updatedCollectionUser.movieList.filter((a) => a.movie.id !== Obj.id);
    setUserCollection(updatedCollectionUser);
    localStorage.setItem("movieProgram", JSON.stringify(updatedCollectionUser));
  };
  const AddObj = (Obj, eList, iden) => {
    let letadd = true;
    if (iden) {
      const elem = document.getElementById(iden);
      if (elem.classList.contains("plus")) {
        elem.classList.remove("plus");
        elem.classList.remove("icon");
        elem.classList.add("icon");
        elem.classList.add("check");
      } else {
        elem.classList.remove("check");
        elem.classList.remove("icon");
        elem.classList.add("icon");
        elem.classList.add("plus");
        letadd = false;
        DeleteObj(Obj);
      }
    }
    if (letadd) {
      let msec = Date.now();
      let startDate = new Date(msec).toISOString();
      const updatedCollectionUser = { ...collectionUser, movieList: [...eList] };
      updatedCollectionUser.movieList.push({
        movie: Obj,
        create_date: startDate,
        isWatched: false,
        isLiked: false,
        isInterested: false,
        modified_date: null,
      });
      setUserCollection(updatedCollectionUser);
      localStorage.setItem("movieProgram", JSON.stringify(updatedCollectionUser));
    }
  };
  // const DeleteAllObj = () => { const updatedCollectionUser = { ...collectionUser, movieList: [] };setUserCollection(updatedCollectionUser);localStorage.setItem("movieProgram", JSON.stringify(updatedCollectionUser));};

  async function searchFunction(query) {
    let sq = query.toUpperCase();
    const response = await axios.get(`assets/movies.json`);
    const data = response.data;
    setCollection(data.filter((a) => a.Actors.toUpperCase().includes(sq) || a.Director.toUpperCase().includes(sq) || a.Title.toUpperCase().includes(sq)));
  }
  const [open, setOpen] = useState([]);
  const OpenCollapse1 = (prop) => {
    setOpen((prevOpen) => ({
      ...prevOpen,
      [prop]: !prevOpen[prop],
    }));
  };
  return (
    <div className="ui container">
      <Modal id="modal" size="lg" show={show} aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Sign Up</Modal.Title>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setShow(false);
            }}
          ></button>
        </Modal.Header>
        <Modal.Body>
          <form className="row gy-2 gx-3 align-items-center">
            <div className="col-auto">
              <label className="visually-hidden" htmlFor="name">
                Name
              </label>
              <div className="input-group">
                <div className="input-group-text">Name</div>
                <input type="text" style={{ fontSize: "16px" }} className="form-control" id="name" placeholder={collectionUser.name || "Name"} />
              </div>
            </div>
            <div className="col-auto">
              <label className="visually-hidden" htmlFor="age">
                Age
              </label>
              <div className="input-group">
                <div className="input-group-text">Age</div>
                <input type="number" style={{ fontSize: "16px" }} className="form-control" id="age" placeholder={collectionUser.age || "00"} />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="ui basic button"
            onClick={() => {
              setShow(false);
            }}
          >
            Cancel
          </button>
          <button
            type="button"
            className="ui basic button"
            onClick={() => {
              saveUser();
            }}
          >
            {collectionUser.name ? "Save" : "Register"}
          </button>
        </Modal.Footer>
      </Modal>
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className="ui breadcrumb" id="main-myTab">
          <button className="ui tertiary blue button" onClick={() => OpenTab("overview", "main")} id="overview-tab">
            Movies
          </button>
          <span className="divider">/</span>
          <button className="ui tertiary grey button" onClick={() => OpenTab("profile", "main")} id="profile-tab">
            {collectionUser.name ? "Profile" : "Register"}
          </button>
        </div>
      </div>
      <div className="tab-content ui active tab segment" id="main-myTabContent">
        <div className="tab-pane fade  show active" id="overview-tab-pane">
          <div className="ui middle aligned divided list">
            <div className="d-flex flex-row justify-content-center">
              <input className="form-control" type="search" id="SearchInput" style={{ fontSize: "16px" }} placeholder="Search..."
                onChange={(event) => searchFunction(event.target.value)} />
              <div className="ui tertiary icon button" onClick={() => OpenCollapse1(`sl`)}>
                <i className="filter icon"></i>
                Filters
              </div>
            </div>
          </div>
          <button className="ui tertiary icon button" onClick={() => handleReverse()}>
            <i id="ascdesc" className="sort amount up icon"></i>
          </button>
          <div className="ui tertiary icon button" onClick={() => goTo("/CytoScape")}>
            🌀
          </div>
          <div className="ui horizontal list">
            {filterFields
              .filter((item) => currentsArray[item] && currentsArray[item].length > 0)
              .map((field, idx) => (
                <div key={idx} className="item">
                  <div className="ui blue labels">
                    <div className="ui sub header">{field}</div>
                    {currentsArray[field].map((a, idx) => (
                      <div key={idx} className="ui left icon teal label" onClick={() => handleCollectionChange(field, a)}>
                        {currentsArray[field] && currentsArray[field].includes(a) ? <i className="close icon"></i> : <i className="bi bi-plus-circle"></i>}
                        &nbsp;{a}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
          </div>
          <Collapse in={open[`sl`]}>
            <div className="">
              <div className="content">
                {filterFields.map((field, idx) => (
                  <div key={idx} className="ui tree accordion">
                    <div className="title" style={{ display: "none" }}></div>
                    <div className="ui middle aligned divided list" onClick={HelperAccordion} ref={myRef}>
                      <div className="item pe-on">
                        <div className="right floated content">
                          <i className="angle right icon"></i>
                        </div>
                        <div className="content">
                          <div className="header">{field}</div>
                        </div>
                      </div>
                    </div>
                    <div className="content">
                      <div key={idx} className="ui blue labels">
                        {filtersArray[field] &&
                          filtersArray[field].map((a, idx) => (
                            <div key={idx} className="ui left icon teal label pe-on" onClick={() => handleCollectionChange(field, a)}>
                              {currentsArray[field] && currentsArray[field].includes(a) ? <i className="close icon"></i> : <i className="bi bi-plus-circle"></i>}
                              &nbsp;{a}
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Collapse>
          {selectedObj.Title && <SelectedItemComponent selectedObj={selectedObj} setSelectedObj={setSelectedObj} />}
          {collection.slice((currentPage - 1) * PerPage, (currentPage - 1) * PerPage + PerPage).map((item, idx) => (
            <div key={idx} className="ui middle aligned divided list">
              <div className="item d-flex">
                <img className="ui tiny image" src={`assets/Movie_Poster_Dataset/${item.Released.split(" ")[2]}/${item.imdbID}.jpg`} alt="Whoops looks like the post is missing." />
                <div className="content flex-grow-1">
                  <span className="right floated">
                    <div className="ui tertiary icon button" onClick={() => window.open(`http://www.google.co.in/search?q=${item.Title} movie`, "_blank")}>
                      <i className="google icon"></i>
                    </div>
                    {collectionUser.name && collectionUser.movieList && (
                      <div className="ui tertiary icon button" onClick={() => AddObj(item, collectionUser.movieList, `add-${item.id}`)}>
                        {collectionUser.movieList.some((a) => a.movie.id === item.id) ? <i id={`add-${item.id}`} className="check icon"></i> : <i id={`add-${item.id}`} className="bi bi-plus-circle"></i>}
                      </div>
                    )}
                  </span>
                  <div className="header text-truncate">{item.Title}</div>
                  <div className="description text-truncate">
                    {Object.entries(item)
                      .filter(([field]) => !["Awards", "Box_office", "Runtime", "Metascore", "imdbVotes", "Writer", "id", "Poster", "Released", "imdbID", "Response", "Title", "Type"].includes(field) && !filterFields.includes(field))
                      .map(([field, value], index) => (
                        <span key={index} className="ui text" style={{ fontSize: "12px" }}>
                          {field}: {value}
                        </span>
                      ))}
                  </div>
                  <div className="slides">
                    <div className="ui list article-list">
                      {Object.entries(item)
                        .filter(([key, value]) => filterFields.includes(key))
                        .map(([key, value], idx) =>
                          value.split(", ").length > 0 ? (
                            value.split(", ").map((a, idx) => (
                              <div key={idx} style={{ minWidth: "90px" }} className="ui left icon teal label" onClick={() => handleCollectionChange(key, key === "Released" ? a.split(" ")[2] : key === "imdbRating" ? Math.floor(Number(a)) : a)}>
                                {currentsArray[key] && currentsArray[key].includes(a) ? <i className="close icon"></i> : <i className="bi bi-plus-circle"></i>}
                                &nbsp;{a}
                              </div>
                            ))
                          ) : (
                            <div key={idx} style={{ minWidth: "90px" }} className="ui left icon teal label" onClick={() => handleCollectionChange(key, value)}>
                              {currentsArray[key] && currentsArray[key].includes(value) ? <i className="close icon"></i> : <i className="bi bi-plus-circle"></i>}
                              &nbsp;{value}
                            </div>
                          )
                        )}
                    </div>
                  </div>
                  <div className="ui horizontal list m-0 p-0" style={{display: "none"}}>
                    {Object.entries(item)
                      .filter(([key, value]) => filterFields.includes(key))
                      .map(([key, value], idx) =>
                        value.split(", ").length > 0 ? (
                          value.split(", ").map((a, idx) => (
                            <div key={idx} className="ui left icon teal label" onClick={() => handleCollectionChange(key, key === "Released" ? a.split(" ")[2] : key === "imdbRating" ? Math.floor(Number(a)) : a)}>
                              {currentsArray[key] && currentsArray[key].includes(a) ? <i className="close icon"></i> : <i className="bi bi-plus-circle"></i>}
                              &nbsp;{a}
                            </div>
                          ))
                        ) : (
                          <div key={idx} className="ui left icon teal label" onClick={() => handleCollectionChange(key, value)}>
                            {currentsArray[key] && currentsArray[key].includes(value) ? <i className="close icon"></i> : <i className="bi bi-plus-circle"></i>}
                            &nbsp;{value}
                          </div>
                        )
                      )}
                  </div>
                </div>
              </div>
            </div>
          ))}
          <PagingComponent PerPage={60} totalPages={totalPages} maxPagesToShow={maxPagesToShow} onPageChange={handlePageChange} />
        </div>
        <div className="tab-pane fade" id="profile-tab-pane">
          {!collectionUser.name ? (
            <div className="fluid ui massive tertiary icon button" onClick={() => setShow(true)}>
              <i className="user icon"></i>
              <div className="header">Register to Start Saving Movies</div>
            </div>
          ) : (
            <div id="overview">
              <h4>My Movie List</h4>
              <div className="ui middle aligned divided relaxed list">
                {collectionUser.movieList.map((a, idx) => (
                  <div key={idx} className="ui middle aligned divided list">
                    <div className="item d-flex">
                      <img className="ui tiny image" src={`assets/Movie_Poster_Dataset/${a.movie.Released.split(" ")[2]}/${a.movie.imdbID}.jpg`} alt="movie" />
                      <div className="content flex-grow-1">
                        <span className="right floated">
                          <div className="ui tertiary icon button" onClick={() => window.open(`http://www.google.co.in/search?q=${a.movie.Title} movie`, "_blank")}>
                            <i className="google icon"></i>
                          </div>
                          <div
                            className="ui tertiary icon button"
                            onClick={() => {
                              a.isLiked = !a.isLiked;
                              const updatedCollectionUser = { ...collectionUser };
                              updateUser(updatedCollectionUser);
                            }}
                          >
                            {a.isLiked ? <i className="thumbs up icon"></i> : <i className="thumbs up outline icon"></i>}
                          </div>
                          <div className="ui tertiary icon button" onClick={() => DeleteObj(a.movie)}>
                            <i className="trash alternate outline icon"></i>
                          </div>
                        </span>
                        <span className="ui sub header">{a.movie.Title}</span>
                        <div className="description">
                          {Object.entries(a.movie)
                            .filter(([field]) => !["Awards", "Box_office", "Runtime", "Metascore", "imdbVotes", "id", "Poster", "Released", "imdbID", "Response", "Title", "Type"].includes(field) && !filterFields.includes(field))
                            .map(([field, value], index) => (
                              <div key={index} className="" style={{ fontSize: "12px" }}>
                                {value}
                                <div className="ui sub header">{field}</div>
                              </div>
                            ))}
                        </div>
                        <div className="ui horizontal list m-0 p-0">
                          {Object.entries(a.movie)
                            .filter(([key, value]) => value !== null && (["Box_office", "Runtime"].includes(key) || filterFields.includes(key)))
                            .map(([key, value], idx) =>
                              value.split(", ").length > 0 ? (
                                value.split(", ").map((a, idx) => (
                                  <div key={idx} className="ui left icon label" onClick={() => handleCollectionChange(key, key === "Released" ? a.split(" ")[2] : key === "imdbRating" ? Math.floor(Number(a)) : a)}>
                                    {a}
                                  </div>
                                ))
                              ) : (
                                <div key={idx} className="ui left icon label" onClick={() => handleCollectionChange(key, value)}>
                                  {value}
                                </div>
                              )
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Movies;
