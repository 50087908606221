import React, { useState } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import "../../assets/styles/Layout.css";

const AppNavbar = () => {
  const location = useLocation();
  const [activeKey, setActiveKey] = useState("/");
  const [isOpen, setIsOpen] = useState(false);
  const [isLight, setIsLight] = useState("light");

  const handleSelect = (eventKey) => {
    setIsOpen(false);
    setActiveKey(eventKey);
  };
  React.useEffect(() => {
    setActiveKey(location.pathname);
    
  }, [location]);
  React.useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    const handleChange = (e) => {
      setIsLight(e.matches ? "dark" : "light");
    };

    handleChange(mediaQuery);
    mediaQuery.addEventListener("change", handleChange);

    return () => {
      mediaQuery.removeEventListener("change", handleChange);
    };
  }, []);

  return (
    <Navbar expand="lg" className="bg-body-tertiary" data-bs-theme={isLight} id="navbar">
      <Container>
        <Navbar.Brand> <div className="d-flex flex-column align-items-center px-3" style={{ fontSize: "10px" }}>
          <img src="apple-touch-icon.png" height="30px" alt="Logo" />
        </div></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav"onClick={() => setIsOpen(!isOpen)} />
        <Navbar.Collapse id="basic-navbar-nav" in={isOpen} onSelect={handleSelect}>
          <Nav className="me-auto" activeKey={activeKey}>
          <Nav.Link as={Link} to="/" eventKey="/" onClick={() => setIsOpen(false)}>Home</Nav.Link>
            <Nav.Link as={Link} to="/Exercise" eventKey="/Exercise"onClick={() => setIsOpen(false)}>Exercise</Nav.Link>
            <Nav.Link as={Link} to="/Services" eventKey="/Services"onClick={() => setIsOpen(false)}>Music</Nav.Link>
            <Nav.Link as={Link} to="/Movies" eventKey="/Movies" onClick={() => setIsOpen(false)}>Movies</Nav.Link>
            <Nav.Link as={Link} to="/Articles" eventKey="/Articles" onClick={() => setIsOpen(false)}>Articles</Nav.Link>
          </Nav>
        </Navbar.Collapse>
        
      </Container>
    </Navbar>
  );
};

export default AppNavbar;
