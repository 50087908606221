import React, { useEffect, useRef } from "react";

const BodySvg = ({ primaryMuscles, secondaryMuscles,clss }) => {
      const theRef = useRef(null);
     useEffect(() => {
        function setProgress(svg) {
            const paths = svg.querySelectorAll("path");
            paths.forEach((child) => {
                const className = child.getAttribute("class");
                if (!className) return;
    
                const normalizedClass = className.replace(/\s+/g, "");
                if (primaryMuscles.includes(normalizedClass)) {
                    child.style.fill = "blue";
                } else if (secondaryMuscles.includes(normalizedClass)) {
                    child.style.fill = "#2ecc40";
                } else {
                    child.style.fill = "";
                }
            });
        }
    
        const svg = theRef.current;
        setProgress(svg);
      }, [primaryMuscles, secondaryMuscles]);
    return (
        <svg ref={theRef} width="301" height="300" viewBox="0 0 301 300" fill="none" xmlns="http://www.w3.org/2000/svg" className={clss}>
        <path d="M85.2852 21.2554L85.1203 12.9691L99.5155 5.07558L114.668 14.5221L114.529 20.7398L115.357 10.0426L108.406 2.65124L98.6695 1L91.1263 2.69237L84.5 11.1063L85.2852 21.2554Z" fill="black" />
        <path d="M112.927 22.3587L112.996 15.2986L99.4208 6.45946L86.6185 13.6095L86.598 22.0379L84.5194 22.1668L85.4573 28.002L88.7985 29.0872L90.3126 36.0604L96.5485 42.6667L103.513 42.4725L109.646 36.2548L110.877 29.0885L114.218 28.0033L115.079 22.297L112.927 22.3587Z" fill="black" />
        <path className="neck" d="M91.3545 38.3333L97.0169 46.2138L99.5715 54.9975L93.6169 50.3619L90.6429 40.4074L91.3545 38.3333ZM108.792 38.3333L103.13 46.2138L100.571 55L106.526 50.3644L109.5 40.4099L108.792 38.3333Z" fill="black" />
        <path className="shoulders" d="M111.391 43.3333L123.535 49.6534L126.016 48.5334L111.391 43.3333ZM111.026 44.7157L109.759 50.2424L117.217 51.9528L122 50.3742L111.026 44.7157ZM124.618 51.0255L127.206 49.7163L135.426 55.0427L139.432 59.8553L141.571 64.8452L141.413 76.6667L136.878 71.1693L137.017 62.8865L135.35 61.2883C135.35 61.2883 124.774 51.1003 124.618 51.0255ZM123.795 51.3727C123.792 51.5404 135.162 62.7005 135.162 62.7005L135.506 72.6125L131.566 66.2967L123.969 63.2693L118.624 54.0571L123.795 51.3727Z" fill="black" />
        <path className="shoulders" d="M89.4656 43.3333L77.3217 49.6534L74.8416 48.5334L89.4656 43.3333ZM89.8308 44.7157L91.098 50.2424L83.6405 51.9528L78.8575 50.3742L89.8308 44.7157ZM76.2394 51.0255L73.6514 49.7163L65.4312 55.0427L61.4248 59.8553L59.2857 64.8451L59.4441 76.6667L63.9788 71.1693L63.8399 62.8865L65.5072 61.2883C65.5072 61.2883 76.0827 51.1003 76.2394 51.0255ZM77.0618 51.3727C77.0686 51.5404 65.6948 62.7005 65.6948 62.7005L65.3511 72.6125L69.2914 66.2967L76.8883 63.2693L82.2333 54.0571L77.0618 51.3727Z" fill="black" />
        <path className="biceps" d="M141.532 99.5095L140.375 105L134.156 96.8071L131.89 92.7208L141.532 99.5095ZM131.78 91.106C131.692 91.0596 131.597 91.0242 131.499 91.0016L126.428 75.0452L129.862 68.3333L135.52 73.9537L143.144 95.4542L143.286 98.4728C139.644 95.7609 135.801 93.2986 131.785 91.1048L131.78 91.106ZM137.573 72.5685L143.209 93.2882L141.963 77.4361L137.573 72.5685Z" fill="black" />
        <path className="biceps" d="M61.0395 99.5095L62.1962 105L68.4153 96.8071L70.6813 92.7208L61.0395 99.5095ZM70.7911 91.106C70.8796 91.0596 70.9743 91.0242 71.0728 91.0016L76.1433 75.0452L72.7094 68.3333L67.0517 73.9537L59.4276 95.4542L59.2857 98.4728C62.9272 95.7609 66.7706 93.2986 70.7862 91.1048L70.7911 91.106ZM64.9987 72.5685L59.3621 93.2882L60.6087 77.4361L64.9987 72.5685Z" fill="black" />
        <path className="forearms" d="M138.151 107.13L142.536 139.968L138.802 140L132.418 114.48L133.064 100L138.151 107.13ZM142.263 100.907L140.283 109.162L144.055 138.384L145.938 137.692L146.714 116.085L142.263 100.907Z" fill="black" />
        <path className="forearms" d="M62.7059 107.13L58.3207 139.968L62.0551 140L68.4395 114.48L67.7931 100L62.7059 107.13ZM58.5939 100.907L60.5737 109.162L56.8023 138.384L54.9196 137.692L54.1429 116.085L58.5939 100.907Z" fill="black" />
        <path className="chest" d="M115.613 55L121.458 65.3661L127.857 68.5448L123.961 71.5853L120.899 80.4312L113.803 83.3333L102.497 77.7831L105.317 55.9669L115.613 55Z" fill="black" />
        <path className="chest" d="M85.2438 55L79.3991 65.3661L73 68.5448L76.8965 71.5853L79.9579 80.4312L87.0539 83.3333L98.3631 77.7831L95.5425 55.9669L85.2438 55Z" fill="black" />
        <path className="abdominals" d="M113.348 112.097L119.517 107.699L120.052 113.607L124.429 121.254L117.691 130.071L114.184 130.604L112.924 118.076L113.348 112.097Z" fill="black" />
        <path className="abdominals" d="M112.147 84.5303L101.56 80L101.879 87.345L111.408 91.9719L112.147 84.5303ZM118.728 89.9061L114.328 92.2299L114.838 86.7235L118.728 89.9061ZM115.146 84.6312L123.582 81.3047L120.406 89.4272L115.146 84.6312ZM120.357 90.2287L114.607 93.5594L113.625 100.222L119.237 96.7571L120.357 90.2287ZM110.452 93.7865L110.241 99.0186L102.406 97.4088L101.982 90.265L110.452 93.7865ZM109.923 101.534L110.029 107.973L102.512 109.886L102.406 99.825L109.923 101.534ZM118.953 98.359L113.906 101.69L113.204 108.485L118.812 104.221L118.953 98.359Z" fill="black" />
        <path className="abdominals" d="M87.507 112.097L81.338 107.699L80.8023 113.607L76.4286 121.257L83.1658 130.072L86.6735 130.605L87.9362 118.081L87.507 112.097Z" fill="black" />
        <path className="abdominals" d="M99.72 145L97.6569 125.951L97.6059 113.55L90.1735 110.086L89.3316 132.737L99.72 145ZM101.135 144.998L103.197 125.949L103.249 113.547L110.679 110.083L111.521 132.735L101.135 144.998Z" fill="black" />
        <path className="abdominals" d="M88.7092 84.5315L99.2959 80.0012L98.9772 87.3463L89.4472 91.9744L88.7092 84.5315ZM82.1263 89.9074L86.5268 92.2317L86.0166 86.7253L82.1263 89.9074ZM85.7086 84.633L77.2742 81.3059L80.4503 89.4302L85.7086 84.633ZM80.4981 90.2305L86.2475 93.5612L87.2296 100.222L81.6206 96.7583L80.4981 90.2305ZM90.4024 93.7883L90.6144 99.0204L98.4498 97.4107L98.8733 90.2674L90.4024 93.7883ZM90.9318 101.535L90.8267 107.974L98.3439 109.886L98.4497 99.825L90.9318 101.535ZM81.9011 98.3608L86.949 101.692L87.6505 108.487L82.0383 104.223L81.9011 98.3608Z" fill="black" />
        <path className="quadriceps" d="M127.179 163.12L126.649 178.722L118.436 200.427L120.953 206.667L129.034 179.265L127.179 163.12ZM125.191 125L124.662 138.159L129.167 164.206L131.286 143.179L125.191 125ZM123.867 141.822L114.461 161.764L112.341 177.771L116.315 200.291L124.925 177.364L125.455 153.761L123.867 141.822Z" fill="black" />
        <path className="quadriceps" d="M75.3916 163.12L75.9216 178.72L84.1348 200.426L81.6176 206.666L73.5364 179.264L75.3916 163.12ZM77.3783 125.002L77.9083 138.16L73.4044 164.206L71.2857 143.179L77.3783 125.002ZM78.7036 141.823L88.1094 161.764L90.2287 177.771L86.2554 200.291L77.6443 177.364L77.1143 153.759L78.7036 141.823Z" fill="black" />
        <path className="adductors" d="M95.3928 146.015L95.4701 145.925L94.9501 144.689L84.1002 133.602L83.2857 133.333L90.4888 150.659L95.3928 146.015ZM90.4888 150.659L95.7581 163.333L97.2493 150.148L95.4701 145.925L90.4888 150.659ZM105.466 146.016L105.388 145.925L105.908 144.69L116.758 133.603L117.571 133.334L110.371 150.659L105.466 146.016ZM110.371 150.659L105.1 163.333L103.609 150.149L105.388 145.925L110.371 150.659Z" fill="black" />
        <path className="abductors" d="M122.714 128.334V141.981L112.564 160.848L106.154 179.58L106.821 166.601L122.714 128.334ZM111.517 171.819L111.294 172.32L107.114 181.719L109.813 205L114.073 202.858L110.381 181.182L111.517 171.819Z" fill="black" />
        <path className="abductors" d="M79.8571 128.333V141.981L90.0057 160.848L96.4159 179.58L95.7486 166.601L79.8571 128.333ZM91.0522 171.817L95.4547 181.718L92.7564 204.999L88.4967 202.858L92.1886 181.181L91.0522 171.817Z" fill="black" />
        <path d="M106.063 285.004L106.483 296.117L107.464 300H110.413L111.256 297.322L112.24 297.188L112.028 299.866L115.748 298.793L116.732 298.258L120.944 297.388L121.085 294.241L117.012 287.683L114.204 284.335L107.323 281.658L106.063 285.004Z" fill="black" />
        <path className="calves" d="M109.432 228.905L110.415 231.85L112.522 244.302L112.802 260.903L109.853 277.104L109.993 256.351L106.905 248.051L106.479 238.947L109.432 228.905ZM116.172 236.403L115.47 261.306L109.853 281.39L115.962 278.644L126.143 243.763L122.632 221.807L116.172 236.403Z" fill="black" />
        <path className="calves" d="M120.104 210.696L120.525 214.311L117.576 217.793L116.593 223.285L114.626 224.087L112.941 215.787L111.818 212.842L112.802 210.967L120.104 210.696ZM107.045 210.964L109.151 216.587L113.644 224.888L112.521 232.52L109.291 223.951L106.626 219.131L107.045 210.964ZM123.894 203.334L120.664 219.803L122.77 218.731L124.455 224.087L123.894 203.334Z" fill="black" />
        <path d="M96.5095 285.003L96.0882 296.116L95.1047 299.999H92.1561L91.3141 297.322L90.3306 297.188L90.5415 299.865L86.8207 298.792L85.8372 298.257L81.6241 297.387L81.4847 294.241L85.5569 287.681L88.3653 284.333L95.2459 281.655L96.5095 285.003Z" fill="black" />
        <path className="calves" d="M93.1396 228.905L92.1561 231.85L90.0495 244.302L89.7689 260.903L92.7194 277.104L92.5779 256.351L95.668 248.051L96.0887 238.947L93.1396 228.905ZM86.3992 236.402L87.101 261.306L92.7183 281.389L86.6093 278.643L76.4286 243.763L79.9393 221.806L86.3992 236.402Z" fill="black" />
        <path className="calves" d="M82.4672 210.696L82.0459 214.311L84.9944 217.793L85.9779 223.285L87.9449 224.087L89.6295 215.787L90.7526 212.842L89.7691 210.967L82.4672 210.696ZM95.5266 210.964L93.42 216.587L88.9265 224.888L90.0495 232.52L93.2792 223.951L95.9473 219.134L95.5266 210.964ZM78.676 203.333L81.9056 219.802L79.7991 218.73L78.1138 224.085L78.676 203.333Z" fill="black" />
        <path d="M57.5562 88.3333L57.5714 98.6349L55.805 105L54.1429 96.9702L57.5562 88.3333Z" fill="black" />
        <path d="M143.301 88.3333L143.286 98.6349L145.052 105L146.714 96.9702L143.301 88.3333Z" fill="black" />
        <path d="M62.2516 141.983L57.0362 142.145L54.4065 140L50.2453 146.895L47.2857 157.533L48.9804 158.239L51.0331 151.201L52.7494 152.011L51.5264 159.982L53.6671 160.76L55.0879 152.797L57.0041 153.367L56.2278 162.206L58.868 163.238L59.753 154.074L61.324 154.016L61.894 162.492L64.1587 163.333L64.4031 148.741L65.5752 148.12L70.2187 153.5L71.3454 152.402L68.8526 145.835L62.2516 141.983Z" fill="black" />
        <path d="M140.32 141.983L145.535 142.145L148.165 140L152.326 146.895L155.286 157.533L153.591 158.239L151.538 151.201L149.822 152.011L151.045 159.982L148.904 160.76L147.484 152.797L145.567 153.367L146.344 162.206L143.703 163.238L142.818 154.074L141.247 154.016L140.677 162.492L138.413 163.333L138.168 148.741L136.996 148.12L132.353 153.5L131.226 152.402L133.719 145.835L140.32 141.983Z" fill="black" />
        <path className="glutes" d="M219.732 128.333L238.658 134.348L246.506 138.664L255.97 133.037L271.564 128.906L274.67 136.316L277 147.396L274.195 163.617L264.44 164.159L250.246 158.753L246.766 160.015L243.458 158.729L228.132 165L218.571 163.339L215.286 143.49L216.554 134.501L219.732 128.333Z" fill="black" />
        <path className="lowerback" d="M240.143 120L246.143 130.833H247.857L253.857 120L260.714 115.833L263.286 114.167L271 128.113L254.554 132.636L246.525 136.667L238.35 132.578L220.429 128.113L229.857 114.167L240.143 120Z" fill="black" />
        <path className="middleback" d="M229.857 113.333L241 120L246.143 130H247.857L253 120L263.286 113.333L251.286 91.6667H242.714L229.857 113.333Z" fill="black" />
        <path d="M231.499 20.4932L233.012 20.009L233.564 20.5405L238.634 33.6376L246.587 38.3333L254.799 33.4023L258.138 21.4631L259.944 19.6541L260.743 19.9715L261.571 9.14872L254.62 1.67063L244.884 0L237.341 1.71224L230.714 10.225L231.499 20.4932Z" fill="black" />
        <path className="neck" d="M246.044 39.6579L237.897 34.5879L232.813 21.6667L230.714 21.7984L231.661 27.7645L235.035 28.8741L237.666 36.1195L238.807 39.6135L239.116 46.6405L243.14 46.6667L243.505 44.3504L245.395 44.1523L246.044 39.6579ZM249.561 44.4842L250.382 46.6091L255.155 46.5961L255.189 38.7878L256.087 36.2025L258.064 29.1072L260.702 27.766L261.571 21.9317L259.399 21.9948L255.651 34.7473L247.367 39.5438L248.323 44.4L249.561 44.4842Z" fill="black" />
        <path d="M215.95 85.6327L217.419 81.0378L221.88 75.5692L217.114 65.9619L209.305 68.9516L203.029 74.6179L201.199 80.4141L202.124 94.3895L215.95 85.6327Z" fill="black" />
        <path className="triceps" d="M277.403 85.6558L276.282 82.2457L271.927 75.5796L276.669 65.9619L284.485 68.9345L290.775 74.587L292.62 80.3792L291.728 94.3566L277.403 85.6558Z" fill="black" />
        <path className="triceps" d="M215.95 85.6327L217.419 81.0378L221.88 75.5692L217.114 65.9619L209.305 68.9516L203.029 74.6179L201.199 80.4141L202.124 94.3895L215.95 85.6327Z" fill="black" />
        <path d="M207.509 135.446L202.318 135.726L199.645 133.608L195.673 140.697L192.992 151.558L194.698 152.238L196.566 145.05L198.296 145.834L197.277 153.95L199.429 154.692L200.645 146.579L202.568 147.116L202.016 156.104L204.672 157.091L205.324 147.772L206.887 147.678L207.668 156.267L209.945 157.071L209.822 142.256L210.974 141.6L215.734 146.957L216.829 145.818L214.181 139.209L207.509 135.446Z" fill="black" />
        <path d="M286.443 135.425L291.635 135.693L294.303 133.569L298.292 140.65L301 151.505L299.296 152.189L297.41 145.005L295.682 145.793L296.72 153.907L294.57 154.653L293.335 146.543L291.413 147.084L291.986 156.07L289.333 157.064L288.659 147.746L287.095 147.655L286.336 156.247L284.06 157.055L284.147 142.24L282.994 141.587L278.246 146.954L277.149 145.818L279.781 139.202L286.443 135.425Z" fill="black" />
        <path d="M207.509 135.446L202.318 135.726L199.645 133.608L195.673 140.697L192.992 151.558L194.698 152.238L196.566 145.05L198.296 145.834L197.277 153.95L199.429 154.692L200.645 146.579L202.568 147.116L202.016 156.104L204.672 157.091L205.324 147.772L206.887 147.678L207.668 156.267L209.945 157.071L209.822 142.256L210.974 141.6L215.734 146.957L216.829 145.818L214.181 139.209L207.509 135.446Z" fill="black" />
        <path d="M207.509 135.41L202.318 135.69L199.645 133.571L195.673 140.661L192.992 151.522L194.698 152.202L196.566 145.014L198.296 145.798L197.277 153.914L199.429 154.656L200.645 146.543L202.568 147.08L202.016 156.067L204.672 157.055L205.324 147.735L206.887 147.642L207.668 156.231L209.945 157.035L209.822 142.22L210.974 141.564L215.734 146.921L216.829 145.782L214.181 139.172L207.509 135.41Z" fill="black" />
        <path d="M204.168 134.869L199.261 132.449L198.214 109.709C198.214 109.709 200.414 100.693 200.392 99.7738L202.475 95.8186L214.871 87.495L212.537 93.9567L213.958 109.359L207.79 134.329L204.168 134.869Z" fill="black" />
        <path className="forearms" d="M289.783 134.841L294.684 132.41L295.676 109.668C295.676 109.668 293.453 100.656 293.474 99.737L291.381 95.7864L277.768 87.4309L281.263 93.7089L279.931 109.352L286.16 134.308L289.783 134.841Z" fill="black" />
        <path d="M204.168 134.869L199.261 132.449L198.214 109.709C198.214 109.709 200.414 100.693 200.392 99.7738L202.475 95.8186L214.871 87.495L212.537 93.9567L213.958 109.359L207.79 134.329L204.168 134.869Z" fill="black" />
        <path className="forearms" d="M204.168 134.833L199.261 132.413L198.214 109.673C198.214 109.673 200.414 100.657 200.392 99.7376L202.475 95.7823L214.871 87.4588L212.537 93.9205L213.958 109.323L207.79 134.293L204.168 134.833Z" fill="black" />
        <path d="M240.488 286.165L243.382 290.599L244.645 293.185L244.116 297.16L241.5 299.591L236.364 300L232.525 299.047L231.702 295.917L234.446 291.66L233.68 288.853L234.688 286.106L234.622 280.18L239.839 278.391L240.488 286.165ZM236.424 287.691L236.966 291.797L234.92 294.978M233.07 295.942L236.688 297.251L241.965 295.124" fill="black" />
        <path d="M251.798 286.165L248.904 290.599L247.64 293.185L248.17 297.16L250.785 299.591L255.922 300L259.76 299.047L260.583 295.917L257.839 291.66L258.606 288.853L257.598 286.106L257.664 280.18L252.446 278.391L251.798 286.165ZM255.861 287.691L255.32 291.797L257.366 294.978M259.215 295.942L255.598 297.251L250.321 295.124" fill="black" />
        <path className="calves" d="M241.313 206.949L237.216 216.79L239.619 217.657L242.668 229.14L240.773 216.044L241.313 206.949ZM224.038 196.667L226.19 202.741L226.761 209.376L228.844 215.68L225.767 215.403L224.797 222.453L224.038 196.667ZM238.651 218.173L242.988 234.485L242.004 244.353L239.698 277.21L234.047 279.283L222.143 240.21L226.389 216.788L238.651 218.173Z" fill="black" />
        <path className="calves" d="M250.972 206.949L255.07 216.79L252.667 217.657L249.617 229.14L251.513 216.044L250.972 206.949ZM268.248 196.667L266.096 202.741L265.524 209.376L263.441 215.68L266.519 215.403L267.488 222.453L268.248 196.667ZM253.635 218.173L249.297 234.485L250.282 244.353L252.588 277.21L258.238 279.283L270.143 240.21L265.897 216.788L253.635 218.173Z" fill="black" />
        <path className="hamstrings" d="M227.633 166.614L243.041 160L245.201 160.749L245.32 166.61L245.574 181.685L238.876 201.29L240.488 205.877L235.653 216.565L229.674 215.588L227.979 208.723L227.405 203.024L219.208 176.559L218.048 164.727L227.633 166.614Z" fill="black" />
        <path className="hamstrings" d="M262.99 165.523L249.517 160L247.608 160.88L247.429 166.712L247.175 181.787L253.873 201.391L252.261 205.979L257.096 216.667L263.075 215.69L264.771 208.825L265.345 203.125L273.541 176.66L273.571 164.711L262.99 165.523Z" fill="black" />
        <path className="shoulders" d="M282.277 52.4003L287.808 57.5298L290.668 64.3106L290.803 72.5974L285.571 68.536L275.731 64.0814L268.705 59.1656L275.719 47.9225L282.277 52.4003Z" fill="black" />
        <path className="shoulders" d="M211.921 52.4003L206.389 57.5298L203.529 64.3106L202.173 72.5974L208.625 68.536L218.24 64.3106L226.652 60.31L218.478 47.9225L211.921 52.4003Z" fill="black" />
        <path className="lats" d="M268.482 60.1681L276.143 65.1286L269.816 75.9732L265.921 93.3322L264.143 112.5L252.068 90.2618L258.718 74.7879L268.482 60.1681Z" fill="black" />
        <path className="lats" d="M226.662 61.1553L218.341 65.1287L224.668 75.9732L228.563 93.3322L229 112.5L241.857 90.2618L235.766 74.7879L226.662 61.1553Z" fill="black" />
        <path className="traps" d="M236.956 46.975L237.174 38.3333L234.705 42.1257L219.571 47.0578L236.773 74.6078L242.714 90L242.34 46.7144L236.956 46.975Z" fill="black" />
        <path className="traps" d="M257.044 47.3016L256.826 38.6599L259.295 42.4523L274.429 47.3844L257.227 74.9344L251.281 90V69.3539L251.66 47.0411L257.044 47.3016Z" fill="black" />
      </svg>
    
    );
};

export default BodySvg;
