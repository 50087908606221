import React from "react";
import "../../assets/styles/Blog.css";

const SelectedItemComponent = ({ selectedObj, setSelectedObj}) => {
  const fields = ["Language", "Country", "Rated", "Released", "imdbRating", "Genre"];
  return (
    <div className="ui middle aligned divided list">
      <div key="1" className="item" style={{ display: "none" }}>
        <div className="right floated content">
          <i className="angle right icon"></i>
        </div>
        <div className="content">
          <div className="header">field</div>
        </div>
      </div>
      <div key="2"className="item w-100">
        <div className="right floated content">
          <div className="ui tertiary icon button" onClick={() => setSelectedObj({})}>
            <i className="close icon"></i>
          </div>
        </div>
        <div className="middle aligned content">
          <div className="header"> {selectedObj.Title}</div>
        </div>
      </div>
      <div key="3"className="item">
        <img className="ui avatar image movie" src={`assets/Movie_Poster_Dataset/${selectedObj.Released.split(" ")[2]}/${selectedObj.imdbID}.jpg`} alt="movie" />
        <div className="content">
          <div className="description">
            {Object.entries(selectedObj)
              .filter(([field]) => !["Awards", "Box_office", "Runtime", "Metascore", "imdbVotes",
                 "Writer", "id", "Poster", "Released", "imdbID", "Response", "Title", "Type"].includes(field) && !fields.includes(field))
              .map(([field, value], index) => (
                <div key={`val-${index}`} className="" style={{ fontSize: "12px" }}>
                  {value}
                  <div className="ui sub header">{field}</div>
                </div>
              ))}
          </div>
          <div className="ui horizontal list m-0 p-0">
            {Object.entries(selectedObj)
              .filter(([key, value]) => fields.includes(key))
              .map(([key, value], idx) =>
                value.split(", ").length > 0 ? (
                  value.split(", ").map((a, idx1) => (
                    <div key={`split-${idx1}`} className="ui left icon teal label">
                      {a}
                    </div>
                  ))
                ) : (
                  <div key={`single-${idx}`} className="ui left icon teal label">
                    {value}
                  </div>
                )
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectedItemComponent;
