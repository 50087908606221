import React, { useState } from "react";

const PagingComponent = ({ PerPage, totalPages, maxPagesToShow, onPageChange }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const getVisiblePages = () => {
    const pages = [];
    let startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
    let endPage = Math.min(startPage + maxPagesToShow - 1, totalPages);
    if (endPage - startPage + 1 < maxPagesToShow && totalPages > maxPagesToShow) {
      startPage = Math.max(endPage - maxPagesToShow + 1, 1);
    }
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    return pages;
  };
  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
      window.scrollTo(0, 0);
      if (onPageChange) {
        onPageChange(page);
      }
    }
  };

  return (
    <div className="d-flex flex-row justify-content-center">
      <div className="ui pagination menu">
        <button className="item" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
          Prev
        </button>
        {currentPage > Math.ceil(maxPagesToShow / 2) && (
          <button className="item" onClick={() => handlePageChange(1)}>
            1
          </button>
        )}
        {currentPage > Math.ceil(maxPagesToShow / 2) && <button className="disabled item">...</button>}
        {getVisiblePages().map((page) => (
          <button key={page} onClick={() => handlePageChange(page)} className={page === currentPage ? "item active1" : "item"}>
            {page}
          </button>
        ))}
        {currentPage < totalPages - Math.floor(maxPagesToShow / 2) && <button className="disabled item">...</button>}
        {currentPage < totalPages - Math.floor(maxPagesToShow / 2) && (
          <button className="item" onClick={() => handlePageChange(totalPages)}>
            {totalPages}
          </button>
        )}
        <button className="item" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
          Next
        </button>
      </div>
    </div>
  );
};

export default PagingComponent;
