import React from "react";
import { Collapse } from "react-bootstrap";
import SwapExercise from "./SwapExercise";
import SetControl from "../Layout/SetControl";
const ProgressExercise = ({ List1, List2, CompleteWorkout, OpenCollapse, selectItem, SecFormat, open, CompleteSet, AddSet, weekDay, DeleteSet, UpdateAllSets, UpdateSet, SwapWorkout, WeekIdx, IsRemaining, DeleteWorkout,resetSetClock,warmup }) => {
  return List1.filter((a) => !IsRemaining || (IsRemaining && a.move.name !== "Mandatory Warm Up")).map((a, idx) => (
    <div key={idx} className="ui middle aligned divided list p-1">
      <div className={`item ${a.move.name !== "Mandatory Warm Up" && "pe-on"} rc-drops m-0`}>
        <div className="left floated content mx-0 px-0">
          <div className="ui tertiary icon button" onClick={() => (IsRemaining ? CompleteWorkout(a, WeekIdx, true) : CompleteWorkout(a, WeekIdx))}>
            {a.iscompleted ? <i className="toggle on icon"></i> : <i className="toggle off icon"></i>}
          </div>
        </div>
        <div className="right floated content">
          {a.move.name !== "Mandatory Warm Up" && (
            <>
              {open[`set-${idx}-${WeekIdx}`] && (
                <button className="ui tertiary icon button" onClick={() => DeleteWorkout(a.move)}>
                  <i className="bi bi-eraser-fill"></i>
                </button>
              )}
              <div
                className="ui tertiary icon button"
                onClick={() => OpenCollapse(`exchange-${idx}-${WeekIdx}`)}
              >
                {open[`exchange-${idx}-${WeekIdx}`] ? <i className="exchange alternate icon" style={{ transform: "rotate(90deg)" }}></i> : <i className="exchange alternate icon"></i>}
              </div>
              <div
                className="ui tertiary icon button"
                onClick={() => OpenCollapse(`set-${idx}-${WeekIdx}`)}
              >
                {open[`set-${idx}-${WeekIdx}`] ? <i className="bi bi-info-circle-fill"></i> : <i className="bi bi-info-circle"></i>}
              </div>
            </>
          )}
        </div>
        <div className="content pe-on m-0" onClick={() => selectItem(a.move.name)}>
          <div className="header text-truncate">
            {IsRemaining && `Day ${WeekIdx + 1} |`}
            {a.sets.length}x {a.move.name}
          </div>
          <div className="description text-truncate">
            {a.move.primaryMuscles.join(", ")} {a.move.secondaryMuscles.join(", ")}
            {!a.move.name.includes("Warm Up") && a.iscompleted && a.totalTime && (
              <span className="ui text">
                <br></br>
                <b>Duration {SecFormat(a.totalTime)}</b>
              </span>
            )}
          </div>
        </div>
      </div>
      <Collapse in={open[`exchange-${idx}-${WeekIdx}`]}>
        <div className="ui middle aligned divided list scrl-on">
          {List2.filter((b) => a.move.name !== b.name && a.move.primaryMuscles[0].toLowerCase() === b.primaryMuscles[0].toLowerCase() && b.secondaryMuscles.length === a.move.secondaryMuscles.length && a.move.secondaryMuscles.every((item) => b.secondaryMuscles.includes(item))).length === 0 && "No similar exercises for this workout!"}
          <SwapExercise Elist={List2.filter((b) => a.move.name !== b.name && a.move.primaryMuscles[0].toLowerCase() === b.primaryMuscles[0].toLowerCase() && b.secondaryMuscles.length === a.move.secondaryMuscles.length && a.move.secondaryMuscles.every((item) => b.secondaryMuscles.includes(item)))} SwapWorkout={SwapWorkout} selectItem={selectItem} Source={a} />
        </div>
      </Collapse>
      <Collapse in={open[`set-${idx}-${WeekIdx}`]}>
        <div className="ui middle aligned divided list">
          <div className="d-flex flex-row justify-content-center">
            <div className="ui tertiary icon button d-flex flex-row align-items-center" onClick={() => AddSet(a.move)}>
              <svg width="19" height="19" className="fill-svg" viewBox="0 0 135 135" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M67.4941 16.875C60.846 16.875 54.2629 18.1845 48.1208 20.7286C41.9787 23.2727 36.3978 27.0018 31.6969 31.7027C26.9959 36.4037 23.2669 41.9845 20.7227 48.1267C18.1786 54.2688 16.8691 60.8518 16.8691 67.5C16.8691 74.1482 18.1786 80.7312 20.7227 86.8734C23.2669 93.0155 26.9959 98.5963 31.6969 103.297C36.3978 107.998 41.9787 111.727 48.1208 114.271C54.2629 116.816 60.846 118.125 67.4941 118.125C80.9207 118.125 93.7974 112.791 103.291 103.297C112.785 93.8032 118.119 80.9266 118.119 67.5C118.119 54.0734 112.785 41.1967 103.291 31.7027C93.7974 22.2087 80.9207 16.875 67.4941 16.875ZM5.61914 67.5C5.61914 33.3281 33.3223 5.625 67.4941 5.625C101.666 5.625 129.369 33.3281 129.369 67.5C129.369 101.672 101.666 129.375 67.4941 129.375C33.3223 129.375 5.61914 101.672 5.61914 67.5Z" />
                <path d="M101.244 67.5C101.244 68.9918 100.651 70.4226 99.5966 71.4775C98.5417 72.5324 97.111 73.125 95.6191 73.125H73.1191V95.625C73.1191 97.1168 72.5265 98.5476 71.4716 99.6025C70.4167 100.657 68.986 101.25 67.4941 101.25C66.0023 101.25 64.5715 100.657 63.5167 99.6025C62.4618 98.5476 61.8691 97.1168 61.8691 95.625V73.125H39.3691C37.8773 73.125 36.4466 72.5324 35.3917 71.4775C34.3368 70.4226 33.7441 68.9918 33.7441 67.5C33.7441 66.0082 34.3368 64.5774 35.3917 63.5225C36.4466 62.4676 37.8773 61.875 39.3691 61.875H61.8691V39.375C61.8691 37.8832 62.4618 36.4524 63.5167 35.3975C64.5715 34.3426 66.0023 33.75 67.4941 33.75C68.986 33.75 70.4167 34.3426 71.4716 35.3975C72.5265 36.4524 73.1191 37.8832 73.1191 39.375V61.875H95.6191C97.111 61.875 98.5417 62.4676 99.5966 63.5225C100.651 64.5774 101.244 66.0082 101.244 67.5Z" />
              </svg>
              &nbsp;Add Set
            </div>
            <div className="ui tertiary icon button" onClick={() => resetSetClock(WeekIdx)}>
              <i className="stopwatch icon"></i>Start/Reset Clock
            </div>
          </div>
          <p className="text-center m-0 p-0 text-muted">{warmup.modified_date}</p>
          <p className="text-center m-0 p-0 text-muted">Current Start Time</p>
          {a.sets.map((set, SetIdx) => (
            <div key={SetIdx} className="">
              <div className="item rc-drops d-flex flex-row align-items-center justify-content-between my-3">
                <div className="d-flex flew-row align-items-center">
                  <div className="ui tertiary icon button" onClick={() => CompleteSet(a, set, weekDay)}>
                    {set.iscompleted ? <i className="toggle on icon"></i> : <i className="toggle off icon"></i>}
                  </div>
                  <div className="content">
                    <div className="header">
                      Set {SetIdx + 1}x{set.reps} {set.weight > 0 ? set.weight + "lbs" : ""}
                    </div>
                    {set.iscompleted && (
                      <span className="ui text">
                        <b>Duration {SecFormat(set.totalTime)}</b>
                      </span>
                    )}
                  </div>
                </div>
                <div className="">
                  {open[`editst-${idx}-${WeekIdx}-${SetIdx}`] && (
                    <button className="ui tertiary icon button" onClick={() => DeleteSet(a.move, set)}>
                      <i className="bi bi-eraser-fill"></i>
                    </button>
                  )}
                  <button className="ui tertiary icon button" onClick={() => OpenCollapse(`editst-${idx}-${WeekIdx}-${SetIdx}`)}>
                    {open[`editst-${idx}-${WeekIdx}-${SetIdx}`] ? <i className="edit icon"></i> : <i className="edit outline icon"></i>}
                  </button>
                </div>
              </div>
              <Collapse in={open[`editst-${idx}-${WeekIdx}-${SetIdx}`]}>
                <div className="ui segment" style={{ overflow: "hidden" }}>
                  <SetControl key={JSON.stringify(set)} UpdateSet={UpdateSet} move={a.move} set={set} SecFormat={SecFormat} UpdateAllSets={UpdateAllSets} />
                </div>
              </Collapse>
            </div>
          ))}
        </div>
      </Collapse>
    </div>
  ));
};

export default ProgressExercise;
