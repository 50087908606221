import React, { useEffect, useRef, useState } from "react";

const Econtrols = ({ EditWorkout, AddWorkout, obj, split, DeleteWorkout }) => {
  const addRefDay = useRef([]);
  const addRefSet = useRef([]);
  const [isDayInView, setIsDayInView] = useState({});
  const [isSetInView, setIsSetInView] = useState({});
  const isAdd = split.flatMap((o) => o.exercises).filter((a) => a.move.name === obj.name).length > 0;
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (entry.intersectionRatio >= 0.75) {
              setIsDayInView(entry.target.textContent.split(" ")[1]);
            }
          }
        });
      },
      {
        root: document.querySelector("#DayPicker"),
        rootMargin: "0px",
        threshold: 1.0,
      }
    );
    const observer1 = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry, index) => {
          if (entry.isIntersecting) {
            if (entry.intersectionRatio >= 0.75) {
              setIsSetInView(entry.target.textContent.split(" ")[0]);
            }
          }
        });
      },
      {
        root: document.querySelector("#SetsPicker"),
        rootMargin: "0px",
        threshold: 1.0,
      }
    );
    const currentRefs = addRefDay.current;

    currentRefs.forEach((ref) => {
      if (ref) {
        observer.observe(ref);
      }
    });
    const currentRefs1 = addRefSet.current;

    currentRefs1.forEach((ref) => {
      if (ref) {
        observer1.observe(ref);
      }
    });
  }, []);
  useEffect(() => {
    const found = split.flatMap((o) => o.exercises).find((a) => a.move.name === obj.name);
    if (found) {
      document.getElementById(`sets-${found.sets.length}`).scrollIntoView({ behavior: "smooth" });
    }
  }, [obj.name, split]);
  const initAdd = (obj, sets, split, isDayInView) => {
    AddWorkout(obj, sets, isDayInView -1);
  };
  const AddItem = (isDayInView) => {
    AddWorkout(obj, Number(isSetInView), isDayInView - 1);
  };
  const EditItem = () => {
    const found = split.flatMap((o) => o.exercises).find((a) => a.move.name === obj.name);
    EditWorkout(found, Number(isSetInView));
    const elem = document.getElementById("updated");
    elem.innerText = "Updated!";
    setTimeout(() => {
      elem.innerText = "Update";
    }, 5000);
  };
  return (
    <div className="content">
      {isAdd ? (
        <div className="d-flex flex-column gap-1">
          <div className="picker" id="SetsPicker">
            <span title="EDIT SETS">
              {Array(12)
                .fill(12)
                .map((_, index) => {
                  return (
                    <span key={index} id={`sets-${index + 1}`} className="pe-on" onClick={() => EditItem()} ref={(el) => (addRefSet.current[index] = el)}>
                      {index + 1} Sets
                    </span>
                  );
                })}
            </span>
          </div>
          <div className="two ui buttons">
            <div className="ui small basic button" onClick={() => EditItem()} id="updated">
              Update
            </div>
            <div
              className="ui small basic button"
              onClick={() => {
                DeleteWorkout(obj);
              }}
            >
              Delete
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column gap-1">
          <div className="picker" id="DayPicker">
            <span title="DAY">
              {Array(7)
                .fill("Day")
                .map((day, index) => (
                  <span key={index} ref={(el) => (addRefDay.current[index] = el)}>
                    {day} {index + 1}
                  </span>
                ))}
            </span>
            <span title="SETS" id="SetsPicker">
              {Array(12)
                .fill(12)
                .map((_, index) => (
                  <span className="pe-on" key={index} onClick={() => initAdd(obj, index + 1, split, isDayInView)} ref={(el) => (addRefSet.current[index] = el)}>
                    {index + 1} Sets
                  </span>
                ))}
            </span>
          </div>
          <div className="ui small basic button" onClick={() => AddItem(isDayInView)}>
              Add Exercise
            </div>
        </div>
      )}
    </div>
  );
};

export default Econtrols;
