import React, { useEffect, useRef } from "react";
import cytoscape from "cytoscape";
import cise from "cytoscape-cise";
import axios from "axios";
cytoscape.use(cise);
const CytoScapeGen = () => {
    const cyRef = useRef(null);
    useEffect(() => {
        const getData = async () => {
            try {
                const response = await axios.get(`assets/exercises.json`);
                const graphData = response.data;
                const nodes = new Map();
                const edges = new Set();
                let graphStyle = cytoscape.stylesheet().selector("node").css({
                    label: "data(id)",
                    width: "100%",
                    shape: "roundrectangle",
                    "background-color": (ele) =>
                        `hsl(${ele.data("cluster") * 72}, 70%, 50%)`,
                    padding: "5px",
                    "text-background-padding": "5px",
                    "text-background-shape": "rectangle",
                    "text-valign": "center",
                    "text-halign": "center",
                }).selector("edge").css({
                    "curve-style": "bezier",
                    "target-arrow-shape": "triangle",
                    'target-arrow-color': '#FF4136',
                    opacity: 1,
                }).selector(".highlighted").css({
                    "background-color": "#0074D9",
                    "line-color": "black",
                    "target-arrow-color": "#FF4136",
                    "source-arrow-color": "black",
                    opacity: 1,
                }).selector(".faded").css({
                    opacity: 0.1,
                    "text-opacity": 0,
                });
                graphData.forEach((element) => {
                    element.secondaryMuscles
                        .forEach((elem) => {
                            if (elem.trim()) {
                                nodes.set(elem, { data: { id: elem } });
                            }
                        });
                });
                graphData.forEach((element) => {
                    const elem = element.secondaryMuscles;
                    elem.forEach((source, i) => {
                        nodes.set(source, { data: { id: source } });
                        for (let j = i + 1; j < elem.length; j++) {
                            const target = elem[j];
                            if (source !== target) {
                                edges.add(
                                    JSON.stringify({ source, target })
                                );
                            }
                        }
                    });
                });
                const elements = [
                    ...nodes.values(),
                    ...[...edges].map((edge) => {
                        const { source, target } = JSON.parse(edge);
                        return { data: { source, target } };
                    }),
                ];
                const nodeList = Array.from(nodes.keys());
                const adjacencyMatrix = Array(nodeList.length)
                    .fill(0)
                    .map(() => Array(nodeList.length).fill(0));

                edges.forEach((edge) => {
                    const { source, target } = JSON.parse(edge);
                    const sourceIndex = nodeList.indexOf(source);
                    const targetIndex = nodeList.indexOf(target);
                    adjacencyMatrix[sourceIndex][targetIndex] = 1;
                    adjacencyMatrix[targetIndex][sourceIndex] = 1;
                });
           
                const cy = cytoscape({
                    container: cyRef.current,
                    elements,
                    style: graphStyle,
                });

                let clusters = cy.elements().markovClustering();
                for (var i = 0; i < clusters.length; i++) {
                    for (var j = 0; j < clusters[i].length; j++) {
                        clusters[i][j]._private.data.clusterID = i;
                    }
                }
                let arrayOfClusterArrays;
                cy.nodes().forEach(function (ele) {
                    var clusterID = ele._private.data.clusterID;
                    if (!arrayOfClusterArrays) {
                        arrayOfClusterArrays = [];
                    }
                    if (!arrayOfClusterArrays[clusterID]) {
                        arrayOfClusterArrays[clusterID] = [];
                    }
                    arrayOfClusterArrays[clusterID].push(ele.data("id"));
                });
                const clusterLabels = nodeList.map((_, i) => i % 5); 
                clusterLabels.forEach((label, index) => {
                  nodes.get(nodeList[index]).data.cluster = label;
                });
                cy.layout({ name: "cise", clusters: arrayOfClusterArrays, }).run();
                cy.on("tap", function (event) {
                    var evtTarget = event.target;
                    if (evtTarget.group && evtTarget.isNode()) {
                        cy.elements().removeClass("highlighted");
                        cy.elements().addClass("faded");
                        const connectedEdges = evtTarget.connectedEdges();
                        var connectedNodes = connectedEdges.connectedNodes();
                        connectedEdges.removeClass("faded").addClass("highlighted");
                        connectedNodes.removeClass("faded").addClass("highlighted");
                        connectedNodes
                            .layout({
                                name: "concentric",
                            })
                            .run();
                    } else {
                        cy.elements().removeClass("faded");
                        cy.elements().removeClass("highlighted");
                        const connectedNodes = cy.nodes().filter((node) => node.connectedEdges().length !== 0);
                        connectedNodes
                            .layout({
                                name: "cise",
                                clusters: arrayOfClusterArrays,
                            })
                            .run();
                    }
                });
                return () => {
                    cy.destroy();
                };
            } catch (error) {
                console.error("Failed to fetch and render data:", error);
            }
        };
        getData();
    }, []);

    return (
        <div
            ref={cyRef}
            style={{
                width: "90vw",
                height: "90vh",
                border: "1px solid #ccc",
                margin: "auto",
            }}
        ></div>
    );
};

export default CytoScapeGen;
