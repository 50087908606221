import React from "react";

const SwapExercise = ({ Elist, SwapWorkout, Source, selectItem }) => {
  return Elist.map((item, idx) => (
    <div key={idx} className={`item ${item.name !== "Mandatory Warm Up" && 'pe-on'} rc-drops my-3`}>
      <div className="right floated content">
        <div className="ui tertiary icon button" onClick={() => SwapWorkout(Source, item)}>
          <i className="exchange alternate icon"></i>
        </div>
      </div>
      <div className="content" onClick={() => selectItem(item.name)}>
        <div className="header">{item.name}</div>
        <div className="description">
          <span className="ui text">{item.primaryMuscles.join(", ")}</span> <span className="ui text">{item.secondaryMuscles.join(", ")}</span>
        </div>
      </div>
    </div>
  ));
};

export default SwapExercise;
