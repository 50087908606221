import React from "react";

const MusicControls = ({ list, PlayObj, selectItem, filtersArray, currentsArray, handleCollectionChange, RemoveItem }) => {
    return (
        <div className="ui middle aligned divided list">
            {list.map((item, idx) => {
                item = item.song ?? {};
                return <div key={idx} className="item rc-drops">
                    <img className="ui tiny image" src={item.image ?? (item.album?.images[0].url ?? '')} alt={"song"} />
                    <div className="content flex-grow-1">
                        <span className="right floated">
                            <div className="ui tertiary icon button" onClick={() => selectItem(item.id, "liked")}>
                                <i className="bi bi-play-circle"></i>
                            </div>
                            <div className="ui tertiary icon button" onClick={() => RemoveItem(item ?? {})}>
                                <i className="trash icon"></i>
                            </div>
                        </span>
                        <div className="header">{item.name ?? ""}</div>
                        <div className="description">
                            <span className="ui text">{typeof item.artists !== "string" ? (item.artists?.map((a) => a.name).join(", ") ?? "") : item.artists}</span>
                        </div>
                        <div className="ui horizontal list m-0 p-0">
                            {Object.entries(filtersArray)
                                .filter(([key]) => !["artist name", "genres", "groupgenres"].includes(key))
                                .map(([key, value], idx) => {
                                    switch (key) {
                                        case "album name":
                                            return (
                                                <div key={idx} className="ui left icon purple label pe-on" onClick={() => handleCollectionChange(key, item.album.name)}>
                                                    {currentsArray[key] && currentsArray[key].includes(item.album.name) ? <i className="close icon"></i> : <i className="bi bi-plus-circle"></i>}
                                                    {item.album.name}
                                                </div>
                                            );
                                        case "release date":
                                            return (
                                                <div key={idx} className="ui left icon purple label pe-on" onClick={() => handleCollectionChange(key, item.album.release_date.slice(0, 7))}>
                                                    {currentsArray[key] && currentsArray[key].includes(item.album.release_date.slice(0, 7)) ? <i className="close icon"></i> : <i className="bi bi-plus-circle"></i>}
                                                    {item.album.release_date}
                                                </div>
                                            );
                                        case "popularity":
                                            return (
                                                <div key={idx} className="ui left icon purple label pe-on" onClick={() => handleCollectionChange(key, item[key])}>
                                                    {currentsArray[key] && currentsArray[key].includes(item[key]) ? <i className="close icon"></i> : <i className="bi bi-plus-circle"></i>}
                                                    P: {item[key]}
                                                </div>
                                            );
                                        default:
                                            return (<div key={idx}></div>);
                                    }
                                })}
                        </div>
                    </div>
                </div>;
            })}
        </div>
    );
};

export default MusicControls;
