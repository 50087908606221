import React, { useEffect, useState, useCallback } from "react";
import SelectedItemComponent from "../components/Music/SelectedItemComponent";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { Collapse } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { HelperAccordion } from "../components/Allpages/HelperFunctions";
import PagingComponent from "../components/Allpages/PagingComponent";
import { Spotifylogin } from "../components/Music/Spotify";
import MusicControls from "../components/Music/MusicControls";
class songProgram {
  constructor() {
    this.name = "spotify User";
    this.code = null;
    this.access_token = null;
    this.expires_in = null;
    this.refresh_token = null;
    this.songList = [];
    this.likedSongs = [];
    this.topSongs = [];
    this.playlistList = [];
    this.createdPlaylist = [];
    this.fetchedSongs = [];
    let msec = Date.now();
    let startDate = new Date(msec).toISOString();
    this.create_date = startDate;
  }
}
const ServicesSkills = () => {
  const [collectionMain, setCollection] = useState([]);
  const [selectedObj, setSelectedObj] = useState({});
  const audioRef = React.useRef(null);
  const myRef = React.useRef(null);
  const [show, setShow] = useState(false);
  const [filtersArray, setFiltersArray] = useState([]);
  const [currentsArray, setCurrentsArray] = useState([]);
  const location = useLocation();
  const [collectionUser, setUserCollection] = useState();
  const PerPage = 60;
  const totalPages = Math.ceil((collectionMain?.length || 0) / PerPage);
  const maxPagesToShow = 3;
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    const GetJson = async () => {
      if (localStorage.getItem("songProgram")) {
        let user = JSON.parse(localStorage.getItem("songProgram"));
        const checkExpired = new Date(user.create_date);
        checkExpired.setSeconds(checkExpired.getSeconds() + 3600);
        if (checkExpired <= new Date()) {
          try {
            const response = await axios.post(`/GetRefreshKey/${user.refresh_token}`);
            let data = response.data;
            user.access_token = data.access_token;
            user.expires_in = data.expires_in;
            user.refresh_token = data.refresh_token ?? user.refresh_token;
            let msec = Date.now();
            user.create_date = new Date(msec).toISOString();
            localStorage.setItem("songProgram", JSON.stringify(user));
          } catch (error) {
            console.log("Whoops, couldn't fetch Updated Posts. Probably on localhost, or the server is down.");
            setUserCollection(user);
          }
        }
        setUserCollection(user);
      }
      const response = await axios.get(`assets/music/SavedSongs.json`);
      let data = response.data.items.some((a) => a.added_at !== undefined) ? (response.data.items.some((a) => a.disc_number !== undefined) ? response.data.items : response.data.items) : response.data;
      setCollection(data);
      let Main = [];
      Object.entries(data[0]).forEach(([key]) => {
        switch (key) {
          case "album":
            Main["album name"] = [
              ...new Set(
                data
                  .flatMap((g) => g.album.name)
                  .sort()
                  .filter((g) => g !== null && g !== undefined && g !== "")
              ),
            ];
            Main["release date"] = [
              ...new Set(
                data
                  .flatMap((g) => g.album.release_date.slice(0, 7))
                  .sort()
                  .filter((g) => g !== null && g !== undefined && g !== "")
              ),
            ];
            break;
          case "artists":
            Main["artist name"] =
              typeof data[0].artists !== "string"
                ? [
                  ...new Set(
                    data
                      .flatMap((g) => g.artists.map((a) => a.name))
                      .sort()
                      .filter((g) => g !== null && g !== undefined && g !== "")
                  ),
                ]
                : [
                  ...new Set(
                    data
                      .flatMap((g) => g.artists.split(","))
                      .sort()
                      .filter((g) => g !== null && g !== undefined && g !== "")
                  ),
                ];
            break;
          case "popularity":
            Main[key] = [
              ...new Set(
                data
                  .flatMap((g) => Math.floor(Number(g.popularity) / 10) * 10)
                  .sort()
                  .filter((g) => g !== null && g !== undefined && g !== "")
              ),
            ];
            break;
          case "danceability":
            Main[key] = [
              ...new Set(
                data
                  .map((g) => Math.floor((Number(g.danceability) * 100) / 10) * 10)
                  .sort()
                  .filter((g) => g !== null && g !== undefined && g !== "")
              ),
            ];
            break;
          case "energy":
            Main[key] = [
              ...new Set(
                data
                  .map((g) => Math.floor((Number(g.energy) * 100) / 10) * 10)
                  .sort()
                  .filter((g) => g !== null && g !== undefined && g !== "")
              ),
            ];
            break;
          case "valence":
            Main[key] = [
              ...new Set(
                data
                  .map((g) => Math.floor((Number(g.valence) * 100) / 10) * 10)
                  .sort()
                  .filter((g) => g !== null && g !== undefined && g !== "")
              ),
            ];
            break;
          case "genres":
            Main[key] = [...new Set(data.flatMap((g) => g.genres.split(",").map((a) => a)).filter((g) => g !== null && g !== undefined && g !== ""))];
            const arr1 = [...new Set(data.flatMap((g) => g.genres.split(",").map((a) => a)).filter((g) => g !== null && g !== undefined && g !== ""))];
            const genres = ["Pop", "Rock", "Hip Hop", "Jazz", "Classical", "Electronic", "Country", "R&B", "Reggae", "Blues", "Metal", "Soul", "Folk", "Punk", "Disco", "Pop", "Rock", "Hip Hop", "Jazz", "Classical", "Electronic", "Country", "R&B", "Reggae", "Blues"].map((a) => a.toLowerCase());
            const countOccurrencesFromArray = (sourceArray, targetArray) => {
              const counts = targetArray.reduce((acc, curr) => {
                acc[curr] = 0;
                return acc;
              }, {});
              sourceArray.forEach((source) => {
                targetArray.forEach((target) => {
                  if (source.includes(target)) {
                    counts[target] = (counts[target] || 0) + 1;
                  }
                });
              });
              return counts;
            };
            const groups = countOccurrencesFromArray(arr1, genres);
            Main["groupgenres"] = Object.keys(groups);
            break;
          case "added_at":
            Main[key] = [
              ...new Set(
                data
                  .flatMap((g) => g.added_at.slice(0, 7))
                  .sort()
                  .filter((g) => g !== null && g !== undefined && g !== "")
              ),
            ];
            break;
          default:
            return "";
        }
      });
      setFiltersArray(Main);
    };
    GetJson();
  }, []);
  useEffect(() => {
    const GetKey = async (code) => {
      try {
        const response = await axios.post(`/GetKey/${code}`);
        let data = response.data;
        if (localStorage.getItem("songProgram")) {
          let user = JSON.parse(localStorage.getItem("songProgram"));
          user.code = code;
          user.access_token = data.access_token;
          user.expires_in = data.expires_in;
          user.refresh_token = data.refresh_token;
          setUserCollection(user);
        } else {
          const user = new songProgram();
          user.code = code;
          user.access_token = data.access_token;
          user.expires_in = data.expires_in;
          user.refresh_token = data.refresh_token;
          localStorage.setItem("songProgram", JSON.stringify(user));
        }
      } catch (error) {
        console.error("Error fetching key:", error);
      }
    };
    const code = new URLSearchParams(location.search).get("code");
    if (code) {
      GetKey(code);
    }
  }, [location]);
  const handlePageChange = (page) => {
    setCurrentPage(page);
    window.scrollTo(0, 0);
  };
  const handleCollectionChange = (option, item) => {
    setCurrentsArray((prevSelected) => {
      const updateList = { ...prevSelected };
      if (item === "") {
        updateList[option] = [];
      } else {
        if (prevSelected[option] && prevSelected[option].includes(item)) {
          updateList[option] = prevSelected[option].filter((g) => g !== item);
        } else {
          updateList[option] = [...(prevSelected[option] || []), item];
        }
      }
      return updateList;
    });
  };
  const updateCollection = useCallback((items, current) => {
    return items.filter((elem) => {
      return Object.entries(current).every(([key, values]) => {
        switch (key) {
          case "album name":
            return values.length === 0 || values.includes(elem.album.name);
          case "release date":
            return values.length === 0 || values.includes(elem.album.release_date.slice(0, 7));
          case "artist name":
            return values.length === 0 || values.some((a) => elem.artists.some((b) => a === b.name));
          case "genres":
            return values.length === 0 || values.some((a) => elem[key].split(",").some((b) => b.includes(a)));
          case "artists":
            return values.length === 0 || values.some((a) => elem[key].split(",").some((b) => a === b));
          case "danceability":
            return values.length === 0 || values.includes(Math.floor((Number(elem[key]) * 100) / 10) * 10);
          case "energy":
            return values.length === 0 || values.includes(Math.floor((Number(elem[key]) * 100) / 10) * 10);
          case "valence":
            return values.length === 0 || values.includes(Math.floor((Number(elem[key]) * 100) / 10) * 10);
          case "popularity":
            return values.length === 0 || values.includes(Math.floor(Number(elem[key]) / 10) * 10);
          case "added_at":
            return values.length === 0 || values.includes(elem[key].slice(0, 7));
          default:
            return values.length === 0 || values.includes(elem[key]);
        }
      });
    });
  }, []);
  function SaveSongs() {
    const name = document.getElementById("playlistname").value;
    const description = document.getElementById("description").value;
    if (!name) {
      alert("Name is required!");
      return;
    }
    SaveSongsAPI(
      name,
      description,
      collectionMain.map((a) => "spotify:track:" + a.id)
    );
    setShow(false);
  }
  const SaveSongsAPI = async (playlistname, description, tracks) => {
    const access_token = collectionUser.access_token;
    try {
      const data = await fetchWebApi("me", "GET", access_token);
      const id = data.id;
      const playlistResponse = await fetchWebApi(`users/${id}/playlists`, "POST", access_token, { name: playlistname, description: description });
      const playlistId = playlistResponse.id;
      await fetchWebApi(`playlists/${playlistId}/tracks`, "POST", access_token, { uris: tracks });
      const playlist = await fetchWebApi(`playlists/${playlistId}`, "GET", access_token);
      const updatedCollectionUser = { ...collectionUser };
      updatedCollectionUser.playlist.push({
        id: playlist.id,
        name: playlist.name,
        artists: playlist.owner.display_name,
        link: playlist.external_urls.spotify,
        preview_url: playlist.tracks.items[0]?.preview_url || null,
        image: playlist.images[0]?.url || null,
        added_at: new Date().toISOString().slice(0, 10).toLocaleString(),
        tracks: playlist.tracks,
      });
      alert(playlist.name + "Playlist saved successfully!");

      setUserCollection(updatedCollectionUser);
      localStorage.setItem("songProgram", JSON.stringify(updatedCollectionUser));
    } catch (error) {
      console.error("Failed to process Spotify API response", error.message);
    }
  };
  async function fetchWebApi(endpoint, method, access_token, body) {
    try {
      const response = await axios({
        url: `https://api.spotify.com/v1/${endpoint}`,
        method: method,
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
        data: body ? JSON.stringify(body) : null,
      });
      return response.data;
    } catch (error) {
      return {
        error: "API_ERROR",
        message: `Spotify API responded with status ${error.response.status}`,
        details: error.response.data,
        status: error.response.status,
      };
    }
  }
  const GetLikedSongs = async () => {
    try {
      const access_token = collectionUser.access_token;
      const data = await fetchWebApi(`me/tracks?&limit=20&offset=20`, "GET", access_token);

      //const response = await axios.get(`assets/music/SavedTracks.json`);
      //const response1 = await axios.get(`assets/music/artists.json`);
      //const data = response.data;
      //const data1 = response1.data;
      const tracks = data.items.flatMap((a) => a.track);
      let ids = [...new Set(tracks.flatMap((a) => a.artists.map((b) => b.id)))].join(",");
      const data1 = await fetchWebApi(`artists?ids=${ids}`, "GET", access_token);
      tracks.map((a) => (a.artists = data1.artists.filter((x) => a.artists.map((b) => b.id).includes(x.id))));
      // let msec = Date.now();
      const updatedCollectionUser = { ...collectionUser };
      updatedCollectionUser.likedSongs = collectionUser.likedSongs ? collectionUser.likedSongs.push(tracks) : tracks;
      //likedSongs: collectionUser.likedSongs ? collectionUser.likedSongs.push(tracks) : [ tracks ]
      setUserCollection(updatedCollectionUser);
      localStorage.setItem("songProgram", JSON.stringify(updatedCollectionUser));
    } catch (error) {
      console.error("Failed to process Spotify API response", error.message);
    }
  };
  // const GetTopSongs = async () => {};
  // const GetPlaylists = async () => {};
  useEffect(() => {
    const fetchAndUpdateCollection = async () => {
      try {
        const response = await axios.get(`assets/music/SavedSongs.json`);
        let data = response.data.items.some((a) => a.added_at !== undefined) ? (response.data.items.some((a) => a.disc_number !== undefined) ? response.data.items : response.data.items) : response.data;
        let updatedList = updateCollection(data, currentsArray);
        setCollection(updatedList);
      } catch (error) {
        console.error("Error fetching JSON:", error);
      }
    };
    fetchAndUpdateCollection();
  }, [currentsArray, updateCollection, collectionUser]);

  const handleReverse = () => {
    let currentList = [...collectionMain];
    const sorted = [...currentList].reverse();
    const elem = document.getElementById("ascdesc");
    if (elem.classList.contains("down")) {
      elem.classList.remove("down");
      elem.classList.add("up");
    } else {
      elem.classList.remove("up");
      elem.classList.add("down");
    }
    setCollection(sorted);
  };
  const flattenObject = (obj, parentKey = "", result = {}) => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        const newKey = parentKey ? `${parentKey}.${key}` : key;
        if (key === "genres") {
          result[key] = [
            ...new Set(
              obj[key]
                .split(",")
                .flatMap((g) => g)
                .filter((g) => g !== null && g !== undefined && g !== "")
            ),
          ];
          break;
        }
        if (typeof obj[key] === "object" && obj[key] !== null) {
          if (Array.isArray(obj[key])) {
            switch (newKey) {
              case "album.artists":
                break;
              case "album.available_markets":
                break;
              case "album.images":
                result["image"] = obj[key][1].url;
                break;
              case "available_markets":
                break;
              case "artists":
                result["artists.names"] = obj[key].map((a) => a.name).join(", ");
                result["genres"] = [...new Set(obj[key].flatMap((g) => g.genres.map((a) => a)).filter((g) => g !== null && g !== undefined && g !== ""))];
                break;
              case "genres":
                result[key] = [...new Set(obj[key].flatMap((g) => g.genres.split(",").map((a) => a)).filter((g) => g !== null && g !== undefined && g !== ""))];
                break;
              default:
                break;
            }
          } else {
            flattenObject(obj[key], newKey, result);
          }
        } else {
          result[newKey] = obj[key];
        }
      }
    }
    return result;
  };

  const selectItem = (Id, type) => {
    let selected;
    switch (type) {
      case "playlist":
        selected = collectionMain.find((g) => g.id === Id);
        break;
      case "liked":
        selected = collectionUser.likedSongs.find((g) => g.id === Id);
        break;
      case "top":
        selected = collectionUser.topSongs.find((g) => g.id === Id);
        break;
      default:
        selected = collectionMain.find((g) => g.id === Id);
        break;
    }
    if (selected) {
      const flattenedSelected = flattenObject(selected);
      setSelectedObj(flattenedSelected);
      if (audioRef && audioRef.current) {
        audioRef.current.pause();
        audioRef.current.load();
        audioRef.current.play();
      }
    } else {
      setSelectedObj({});
      if (audioRef && audioRef.current) {
        audioRef.current.pause();
      }
    }
  };
  const RemoveItem = (item) => {
    const updatedList = { ...collectionUser };
    updatedList.songList = updatedList.songList.filter((a) => a.song !== item);
    setUserCollection(updatedList);
    localStorage.setItem("songProgram", JSON.stringify(updatedList));
  };
  // const generateItemContent = (selectedObj) => {
  //   return (
  //     <div className="item d-flex">
  //       <img className="ui tiny image" src={selectedObj.image ?? selectedObj.album.images[0].url} alt="movie" />
  //       <div className="content  flex-grow-1">
  //         <span className="right floated">
  //           <div className="ui tertiary icon button" onClick={() => document.getElementById("offcanvasBottom").classList.add("show")}>
  //             <i className="up arrow icon"></i>
  //           </div>
  //           <div className="ui tertiary icon button" onClick={() => window.open(`http://www.google.co.in/search?q=${selectedObj.name} song`, "_blank")}>
  //             <i className="google icon"></i>
  //           </div>
  //           <div className="ui tertiary icon button" onClick={() => setSelectedObj({})}>
  //             <i className="close icon"></i>
  //           </div>
  //         </span>
  //         <div className="header">{selectedObj.name}</div>
  //         <div className="description">
  //           <span className="ui text">{typeof selectedObj.artists !== "string" ? selectedObj.artists.map((a) => a.name).join(", ") : selectedObj.artists}</span>
  //         </div>
  //         <div className="description audio">
  //           {selectedObj.preview_url && (
  //             <audio controls ref={audioRef} crossOrigin="anonymous">
  //               <source src={selectedObj.preview_url} type="audio/mpeg" />
  //             </audio>
  //           )}
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };
  const ResetAllFilters = () => {
    let resetAll = {};
    filtersArray.forEach((element) => {
      resetAll[element] = [];
    });
    setCurrentsArray(resetAll);
  };
  const DeleteObj = (Obj) => {
    const updatedCollectionUser = { ...collectionUser, songList: [...collectionUser.songList] };
    updatedCollectionUser.songList = updatedCollectionUser.songList.filter((a) => a.song.name !== Obj.name);
    setUserCollection(updatedCollectionUser);
    localStorage.setItem("songProgram", JSON.stringify(updatedCollectionUser));
  };
  const AddObj = (Obj, iden) => {
    let letadd = true;
    if (iden) {
      const elem = document.getElementById(iden);
      if (elem.classList.contains("bi-check-circle")) {
        elem.classList.remove("bi-check-circle");
        elem.classList.add("bi-check-circle-fill");
      } else {
        elem.classList.remove("bi-check-circle-fill");
        elem.classList.remove("bi-check-circle");
        letadd = false;
        DeleteObj(Obj);
      }
    }
    if (letadd) {
      let msec = Date.now();
      let startDate = new Date(msec).toISOString();
      if (localStorage.getItem("songProgram")) {
        let songlist = collectionUser?.songList ? collectionUser.songList : [];
        const updatedCollectionUser = { ...collectionUser, songList: [...songlist] };
        updatedCollectionUser.songList.push({
          song: Obj,
          create_date: startDate,
          isLiked: false,
          isInterested: false,
          modified_date: null,
        });
        setUserCollection(updatedCollectionUser);
        localStorage.setItem("songProgram", JSON.stringify(updatedCollectionUser));
      } else {
        const newUser = new songProgram();
        newUser.songList.push({
          song: Obj,
          create_date: startDate,
          isLiked: false,
          isInterested: false,
          modified_date: null,
        });
        setUserCollection(newUser);
        localStorage.setItem("songProgram", JSON.stringify(newUser));
      }
    }
  };
  const ImportData = () => {
    const element = document.getElementById("fitnessdata").value;
    let user = JSON.parse(element);
    if (user.name === "spotify User" && typeof user === typeof collectionUser) {
      setUserCollection(user);
      localStorage.setItem("songProgram", JSON.stringify(user));
      document.getElementById("fitnessdata").value = "";
      alert("Data Imported Successfully");
    } else {
      document.getElementById("fitnessdata").value = "";
      alert("Whoops, couldn't use that data. Probably a user error, or a malicious attempt. Please try again.");
    }
  };
  const CopyData = () => {
    const userData = JSON.stringify(collectionUser); // const url = `data:text/json;charset=utf-8,${encodeURIComponent(userData)}`;
    const element = document.getElementById("copieddata");
    navigator.clipboard
      .writeText(userData)
      .then(() => {
        element.innerText = "Copied!";
        setTimeout(() => {
          element.innerText = "Copy Data";
        }, 5000);
      })
      .catch((err) => {
        element.innerText = "Action Failed";
        setTimeout(() => {
          element.innerText = "Copy Data";
        }, 5000);
      });
  };
  function OpenTab(id, type) {
    const collapseElement = document.getElementById(`${id}-tab-pane`);
    const buttonElement = document.getElementById(`${id}-tab`);
    document.querySelectorAll(`#${type}-myTab .blue, #${type}-myTabContent .show, #${type}-myTabContent .active`).forEach(el => {
      el.classList.remove("blue", "show", "active");
      el.classList.add("grey");
    });
    collapseElement.classList.add("show", "active");
    buttonElement.classList.remove("grey");
    buttonElement.classList.add("blue");
  }
  const [open, setOpen] = useState([]);
  const OpenCollapse2 = (prop) => {
    setOpen((prevOpen) => ({
      ...prevOpen,
      [prop]: !prevOpen[prop],
    }));
  };
  return (
    <div className="ui container">
      <div className="offcanvas offcanvas-bottom" tabIndex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasBottomLabel">
            {selectedObj.name}
          </h5>
          <button type="button" className="btn-close" onClick={() => document.getElementById("offcanvasBottom").classList.remove("show")} aria-label="Close"></button>
        </div>
        <div className="offcanvas-body small">
          <div className="ui list">
            {Object.entries(selectedObj).map(([key, value], idx) => (
              <div key={idx} className="item">
                <h6 className="header">{key}</h6>
                <div className="description">{value}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="offcanvas offcanvas-end" id="filters" aria-labelledby="offcanvasLabel1">
        <div className="offcanvas-header">
          <div className="content p-1">
            <div className="ui sub header">Song Filters</div>
            <div className="meta">
              <span className="ui sub header">{collectionMain.length}&nbsp; Songs</span>
            </div>
          </div>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              document.getElementById("filters").classList.remove("show");
            }}
          ></button>
        </div>
        <div className="offcanvas-body">
          {Object.entries(filtersArray).some(([field]) => currentsArray[field] && currentsArray[field].length > 0) && (
            <div className="content">
              <div className="ui sub header">Active filters</div>
              <div className="ui left icon teal label" onClick={() => ResetAllFilters()}>
                <i className="close icon"></i>
                all filters
              </div>
              <div className="ui list">
                {Object.entries(currentsArray)
                  .filter(([_, values]) => values.length > 0)
                  .map(([key, values], idx) => (
                    <div key={idx} className="ui blue labels">
                      <div className="ui sub header">{key}</div>
                      {values.map((a, idx) => (
                        <div key={idx} className="ui left icon teal label" onClick={() => handleCollectionChange(key, a)}>
                          <i className="close icon"></i>
                          {a}
                        </div>
                      ))}
                    </div>
                  ))}
              </div>
            </div>
          )}
          <div className="content">
            {Object.entries(filtersArray)
              .filter(([key]) => !["genres", "groupgenres"].includes(key))
              .map(([field], idx) => (
                <div key={idx} className="ui tree accordion">
                  <div className="title" style={{ display: "none" }}></div>
                  <div className="ui middle aligned divided list" onClick={HelperAccordion} ref={myRef}>
                    <div className="item pe-on">
                      <div className="right floated content">
                        <i className="angle right icon"></i>
                      </div>
                      <div className="content">
                        <div className="header">{field}</div>
                      </div>
                    </div>
                  </div>
                  <div className="content">
                    <div key={idx} className="ui blue labels">
                      {filtersArray[field] &&
                        filtersArray[field].map((a, idx) => (
                          <div key={idx} className="ui left icon teal label pe-on" onClick={() => handleCollectionChange(field, a)}>
                            {currentsArray[field] && currentsArray[field].includes(a) ? <i className="close icon"></i> : <i className="bi bi-plus-circle"></i>}
                            {a}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              ))}
            <div className="ui tree accordion">
              <div className="title" style={{ display: "none" }}></div>
              <div className="ui middle aligned divided list" onClick={HelperAccordion} ref={myRef}>
                <div className="item pe-on">
                  <div className="right floated content">
                    <i className="angle right icon"></i>
                  </div>
                  <div className="content">
                    <div className="header">genres</div>
                  </div>
                </div>
              </div>
              <div className="content">
                <div className="ui blue labels">
                  {filtersArray["groupgenres"] &&
                    filtersArray["groupgenres"].map((field, idx) => (
                      <div key={idx} className="ui tree accordion">
                        <div className="title" style={{ display: "none" }}></div>
                        <div className="ui middle aligned divided list" onClick={HelperAccordion} ref={myRef}>
                          <div className="item pe-on">
                            <div className="right floated content">
                              <i className="angle right icon"></i>
                            </div>
                            <div className="content">
                              <div className="header">{field}</div>
                            </div>
                          </div>
                        </div>
                        <div className="content">
                          <div key={idx} className="ui blue labels">
                            {filtersArray["genres"]
                              .filter((a) => a.includes(field))
                              .map((a, idx) => (
                                <div key={idx} className="ui left icon teal label pe-on" onClick={() => handleCollectionChange("genres", a)}>
                                  {currentsArray["genres"] && currentsArray["genres"].includes(a) ? <i className="close icon"></i> : <i className="bi bi-plus-circle"></i>}
                                  {a}
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="content">
              <button className="ui secondary basic button" onClick={() => (window.location.href = "/CytoScapeGen")}>
                🐇🥚
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="offcanvas offcanvas-end" id="settings">
        <div className="offcanvas-header">
          <h5 className="offcanvas-title">Settings</h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              document.getElementById("settings").classList.remove("show");
            }}
          ></button>
        </div>
        <div className="offcanvas-body">
          {collectionUser && (
            <div className="ui tree accordion">
              <div className="title" style={{ display: "none" }}></div>
              <div className="ui middle aligned divided list" onClick={HelperAccordion} ref={myRef}>
                <div className="item pe-on">
                  <div className="right floated content">
                    <i className="angle right icon"></i>
                  </div>
                  <div className="content">
                    <div className="header">Acount Options</div>
                  </div>
                </div>
              </div>
              <div className="content">
                <div className="d-flex flex-column gap-2">
                  <div className="ui basic button" onClick={() => setShow(true)}>
                    Edit account
                  </div>
                  <div
                    className="ui basic button"
                    onClick={() => {
                      localStorage.removeItem("songProgram");
                      setUserCollection({});
                    }}
                  >
                    Delete account
                  </div>
                  {collectionUser.songList && collectionUser.songList.flatMap((o) => o.song) && (
                    <div className="ui basic button" onClick={() => setUserCollection({ ...collectionUser, songList: [] })}>
                      Delete all songs
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {collectionUser && (
            <div className="ui tree accordion">
              <div className="title" style={{ display: "none" }}></div>
              <div className="ui middle aligned divided list" onClick={HelperAccordion} ref={myRef}>
                <div className="item pe-on">
                  <div className="right floated content">
                    <i className="angle right icon"></i>
                  </div>
                  <div className="content">
                    <div className="header">My Data</div>
                  </div>
                </div>
              </div>
              <div className="content">
                <div className="ui tree accordion">
                  <div className="title" style={{ display: "none" }}></div>
                  <div className="ui middle aligned divided list" onClick={HelperAccordion} ref={myRef}>
                    <div className="item pe-on">
                      <div className="right floated content">
                        <i className="angle right icon"></i>
                      </div>
                      <div className="content">
                        <div className="header">Import Data</div>
                      </div>
                    </div>
                  </div>
                  <div className="content">
                    <form className="ui form" id="commentForm">
                      <div className="field">
                        <span className="ui teal text">Fitness Data</span>
                        <textarea style={{ fontSize: "16px" }} className="form-control" id="fitnessdata" placeholder="Paste copied data here, you can then choose changes later or take over this data as your own..."></textarea>
                      </div>
                    </form>
                    <div className="d-flex flex-column gap-2">
                      <button type="button" className="ui icon red button mt-3" onClick={() => ImportData()}>
                        <i className="bi bi-arrow-up-square-fill"></i> &nbsp; Import
                      </button>
                    </div>
                  </div>
                  <div className="title" style={{ display: "none" }}></div>
                  <div className="ui middle aligned divided list" onClick={HelperAccordion} ref={myRef}>
                    <div className="item pe-on">
                      <div className="right floated content">
                        <i className="angle right icon"></i>
                      </div>
                      <div className="content">
                        <div className="header">View Data</div>
                      </div>
                    </div>
                  </div>
                  <div className="content">
                    <div className="d-flex flex-column gap-2">
                      <div id="copieddata" className="ui black button" onClick={() => CopyData()}>
                        Copy Data
                      </div>
                    </div>
                    <pre>{JSON.stringify(collectionUser, null, 2)}</pre>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal id="modal" size="lg" show={show} aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Save Songs</Modal.Title>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setShow(false);
            }}
          ></button>
        </Modal.Header>
        <Modal.Body>
          <p>You Are about to save {collectionMain.length} Songs, Proceed?</p>
          <form className="row gy-2 gx-3 align-items-center">
            <div className="col-auto">
              <div className="input-group">
                <div className="input-group-text">What do you wanna call this?</div>
                <input type="text" style={{ fontSize: "16px" }} className="form-control" id="playlistname" />
              </div>
            </div>
            <div className="col-auto">
              <div className="input-group">
                <div className="input-group-text">Add description?</div>
                <input type="number" style={{ fontSize: "16px" }} className="form-control" id="description" />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="ui red basic button"
            onClick={() => {
              setShow(false);
            }}
          >
            Cancel
          </button>
          <button
            type="button"
            className="ui green basic button"
            onClick={() => {
              SaveSongs();
            }}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className="ui breadcrumb" id="main-myTab">
          <button className="ui tertiary blue button" onClick={() => OpenTab("overview", "main")} id="overview-tab">Music</button>
          <span className="divider">/</span>
          <button className="ui tertiary grey button" onClick={() => OpenTab("saved", "main")} id="saved-tab">Yours</button>
        </div>
        <div className="d-flex flex-row align-items-center">
          <div
            className="ui tertiary icon button d-flex flex-column align-items-center"
            onClick={() => {
              document.getElementById("filters").classList.add("show");
            }}
          >
            <i className="filter icon"></i>
          </div>
          <button className="ui tertiary icon button" onClick={() => handleReverse()}>
            <i id="ascdesc" className="sort amount up icon"></i>
          </button>
          <div
            className="ui tertiary icon button"
            onClick={() => {
              document.getElementById("settings").classList.add("show");
            }}
          >
            <i className="cog icon"></i>
          </div>
          {collectionUser ? (
            <div className="ui tertiary icon button d-flex flex-column align-items-center" onClick={() => setShow(true)}>
              <i className="bi bi-download"></i>
            </div>
          ) : (
            <div className="ui tertiary icon button d-flex flex-column align-items-center" onClick={() => Spotifylogin()}>
              <i className="spotify icon"></i>
              Login
            </div>
          )}
        </div>
      </div>
      <div className="tab-content ui active tab segment mx-0 px-0" id="main-myTabContent">
        <div className="tab-pane fade show active" id="overview-tab-pane">
          <h5 className="text-center">{collectionMain.length} Songs</h5>
          <div className="ui middle aligned divided list">
            {collectionMain.slice((currentPage - 1) * PerPage, (currentPage - 1) * PerPage + PerPage).map((item, idx) => {
              return (
                <div key={idx} className="item rc-drops p-2">
                  <img className="ui tiny image" src={item.image ?? item.album.images[0].url} alt={"song"} />
                  <div className="content flex-grow-1">
                    <span className="right floated">
                      <div className="ui tertiary icon button" onClick={() => selectItem(item.id)}>
                        <i className="bi bi-play-circle"></i>
                      </div>
                      <div className="ui tertiary icon button" onClick={() => AddObj(item, `check-${item.name}`)}>
                        <i id={`check-${item.name ?? ""}`} className="bi bi-check-circle"></i>
                      </div>
                    </span>
                    <div className="header">{item.name ?? ""}</div>
                    <div className="description">
                      <span className="ui text">{typeof item.artists !== "string" ? item.artists.map((a) => a.name).join(", ") : item.artists}</span>
                    </div>
                    <div className="slides">
                    <div className="ui list article-list">
                      {Object.entries(filtersArray)
                        .filter(([key]) => !["artist name", "genres", "groupgenres"].includes(key))
                        .map(([key, value], idx) => {
                          switch (key) {
                            case "album name":
                              return (
                                <div key={idx} className="ui left icon purple label pe-on"style={{ minWidth: "70px" }} onClick={() => handleCollectionChange(key, item.album.name)}>
                                  {currentsArray[key] && currentsArray[key].includes(item.album.name) ? <i className="close icon"></i> : <i className="bi bi-plus-circle"></i>}
                                  &nbsp;{item.album.name}
                                </div>
                              );
                            case "release date":
                              return (
                                <div key={idx} className="ui left icon purple label pe-on"style={{ minWidth: "70px" }} onClick={() => handleCollectionChange(key, item.album.release_date.slice(0, 7))}>
                                  {currentsArray[key] && currentsArray[key].includes(item.album.release_date.slice(0, 7)) ? <i className="close icon"></i> : <i className="bi bi-plus-circle"></i>}
                                  &nbsp;{item.album.release_date}
                                </div>
                              );
                            case "added_at":
                              return (
                                <div key={idx} className="ui left icon purple label pe-on"style={{ minWidth: "70px" }} onClick={() => handleCollectionChange(key, item[key].slice(0, 7))}>
                                  {currentsArray[key] && currentsArray[key].includes(item[key].slice(0, 7)) ? <i className="close icon"></i> : <i className="bi bi-plus-circle"></i>}
                                  &nbsp;{item[key].slice(0, 7)}
                                </div>
                              );
                            case "popularity":
                              return (
                                <div key={idx} className="ui left icon purple label pe-on"style={{ minWidth: "70px" }} onClick={() => handleCollectionChange(key, item[key])}>
                                  {currentsArray[key] && currentsArray[key].includes(item[key]) ? <i className="close icon"></i> : <i className="bi bi-plus-circle"></i>}
                                  &nbsp;P: {item[key]}
                                </div>
                              );
                            default:
                              return (
                                <div key={idx} className="ui left icon purple label pe-on"style={{ minWidth: "70px" }} onClick={() => handleCollectionChange(key, Math.floor((Number(item[key]) * 100) / 10) * 10)}>
                                  {currentsArray[key] && currentsArray[key].includes(item[key]) ? <i className="close icon"></i> : <i className="bi bi-plus-circle"></i>}
                                  &nbsp;{key.substring(0, 1)}: {Math.floor((Number(item[key]) * 100) / 10) * 10}
                                </div>
                              );
                          }
                        })}
                    </div>
                    </div>
                  </div>
                </div>
              );
            })}
            <PagingComponent PerPage={60} totalPages={totalPages} maxPagesToShow={maxPagesToShow} onPageChange={handlePageChange} />
          </div>
        </div>
        <div className="tab-pane fade" id="saved-tab-pane">
          {collectionUser && (
            <div className="ui middle aligned divided list">
              <div className="item pe-on rc-drops" onClick={() => OpenCollapse2(`sl`)}>
                <div className="right floated content">{open[`sl`] ? <i className="angle down icon"></i> : <i className="angle right icon"></i>}</div>
                <div className="content">
                  <div className="header">
                    Song List
                  </div>
                </div>
              </div>
              <Collapse in={open[`sl`]}>
                <div className="">
                  {collectionUser.songList && (<MusicControls list={collectionUser.songList} setSelectedObj={setSelectedObj} selectItem={selectItem} AddObj={AddObj} RemoveItem={RemoveItem}
                    filtersArray={filtersArray} currentsArray={currentsArray} handleCollectionChange={handleCollectionChange} />)}
                </div>
              </Collapse>
              <div className="item pe-on rc-drops" onClick={() => OpenCollapse2(`one`)}>
                <div className="right floated content">{open[`one`] ? <i className="angle down icon"></i> : <i className="angle right icon"></i>}</div>
                <div className="content">
                  <div className="header">
                    Controls
                  </div>
                </div>
              </div>
              <Collapse in={open[`one`]}>
                <div className="">
                  <div className="d-flex flex-column gap-2">
                    <div className="ui green button" onClick={() => GetLikedSongs()}>
                      Get Liked Songs
                    </div>
                    <div className="ui purple button">Get Top Songs</div>
                    <div className="ui brown button">Get All Playlists</div>
                  </div>
                </div>
              </Collapse>
              {collectionUser.playlist > 0 && (
                <div className="ui middle aligned divided list">
                  <div className="item pe-on rc-drops" onClick={() => OpenCollapse2(`two`)}>
                    <div className="right floated content">{open[`two`] ? <i className="angle down icon"></i> : <i className="angle right icon"></i>}</div>
                    <div className="content">
                      <div className="header">
                        Playlists
                      </div>
                    </div>
                  </div>
                  <Collapse in={open[`two`]}>
                    <div>
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>playlist name</th>
                            <th>link</th>
                            <th>Created</th>
                          </tr>
                        </thead>
                        <tbody>
                          {collectionUser.playlist.map((item, idx) => {
                            return (
                              <tr key={idx}>
                                <td>{item.name}</td>
                                <td>
                                  <a href={item.link}>{item.link}</a>
                                </td>
                                <td>{item.added_at}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </Collapse>
                </div>
              )}
              {collectionUser && collectionUser.likedSongs.length > 0 && (
                <div className="ui middle aligned divided list">
                  <div className="item pe-on rc-drops" onClick={() => OpenCollapse2(`three`)}>
                    <div className="right floated content">{open[`three`] ? <i className="angle down icon"></i> : <i className="angle right icon"></i>}</div>
                    <div className="content">
                      <div className="header">
                        Liked Songs
                      </div>
                    </div>
                  </div>
                  <Collapse in={open[`three`]}>
                    <div className="">
                      {collectionUser.likedSongs && (<MusicControls list={collectionUser.likedSongs} setSelectedObj={setSelectedObj} selectItem={selectItem} AddObj={AddObj}
                        filtersArray={filtersArray} currentsArray={currentsArray} handleCollectionChange={handleCollectionChange} />)}
                    </div>
                  </Collapse>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {selectedObj.name && <SelectedItemComponent selectedObj={selectedObj} setSelectedObj={setSelectedObj} audioRef={audioRef} />}
    </div>
  );
};
export default ServicesSkills;
