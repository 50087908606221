import React, { useState, useEffect, useRef, useCallback } from "react";
import { HelperAccordion } from "../components/Allpages/HelperFunctions";
import "../assets/styles/Home.css";
import Collapse from "react-bootstrap/Collapse";
const Home = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth >= 1127 ? 1127 : window.innerWidth >= 933 ? 933 : window.innerWidth >= 723 ? 723 : window.innerWidth);
  const totalSlides = 4;
  const sliderRef = useRef(null);
  const myRef = React.useRef(null);

  useEffect(() => {
    let width = window.innerWidth >= 1127 ? 1127 : window.innerWidth >= 933 ? 933 : window.innerWidth >= 723 ? 723 : window.innerWidth;
    const handleResize = debounce(() => setWindowWidth(width), 200);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(this, args), delay);
    };
  };
  const sliderStyle = {
    transform: `translate3d(-${currentIndex * windowWidth}px, 0, 0)`,
    transition: "transform 500ms ease",
    width: `${windowWidth * totalSlides}px`,
  };
  const handleNext = useCallback(() => {
    setCurrentIndex((prevIndex) => {
      return prevIndex > 0 ? prevIndex - 1 : totalSlides - 1;
    });
  }, [totalSlides]);

  const handlePrev = useCallback(() => {
    setCurrentIndex((prevIndex) => {
      return prevIndex < totalSlides - 1 ? prevIndex + 1 : 0;
    });
  }, [totalSlides]);

  const handleDotClick = useCallback((index) => {
    setCurrentIndex(index);
    if (sliderRef.current) {
      sliderRef.current.scrollTo({ left: index * sliderRef.current.offsetWidth, behavior: "smooth" });
    }
  }, []);

  const goTo = (Link) => {
    window.location.href = Link;
  };
  const CopyData = (key) => {
    const userData = JSON.stringify(localStorage.getItem(key));
    const element = document.getElementById("copieddata");
    navigator.clipboard
      .writeText(userData)
      .then(() => {
        element.innerText = "Copied!";
        setTimeout(() => {
          element.innerText = "Copy Data";
        }, 5000);
      })
      .catch((err) => {
        element.innerText = "Action Failed";
        setTimeout(() => {
          element.innerText = "Copy Data";
        }, 5000);
      });
  };
  const [open, setOpen] = useState([]);
  const OpenCollapse = (prop) => {
    setOpen((prevOpen) => ({
      ...prevOpen,
      [prop]: !prevOpen[prop],
    }));
  };
  function useTime() {
    const [time, setTime] = useState(() => new Date());
    useEffect(() => {
      const id = setInterval(() => {
        setTime(new Date());
      }, 1000);
      return () => clearInterval(id);
    }, []);
    return time;
  }

  const time = useTime();
  return (
    <div className="ui container">
      <div className="offcanvas offcanvas-end" id="settings">
        <div className="offcanvas-header">
          <h5 className="offcanvas-title">Settings</h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              document.getElementById("settings").classList.remove("show");
            }}
          ></button>
        </div>
        <div className="offcanvas-body">
          <div className="ui tree accordion">
            <div className="title" style={{ display: "none" }}></div>
            <div className="ui middle aligned divided list" onClick={HelperAccordion} ref={myRef}>
              <div className="item pe-on">
                <div className="right floated content">
                  <i className="angle right icon"></i>
                </div>
                <div className="content">
                  <div className="header">My Data</div>
                </div>
              </div>
            </div>
            <div className="content">
              {Object.keys(localStorage).map((key, idx) => {
                const value = localStorage.getItem(key);
                return (
                  <div key={idx} className="ui tree accordion">
                    <div className="title" style={{ display: "none" }}></div>
                    <div className="ui middle aligned divided list" onClick={HelperAccordion} ref={myRef}>
                      <div className="item pe-on">
                        <div className="right floated content">
                          <i className="angle right icon"></i>
                        </div>
                        <div className="content">
                          <div className="header">{key}</div>
                        </div>
                      </div>
                    </div>
                    <div className="content">
                      <pre>{value}</pre>
                      <div className="d-flex flex-column gap-2">
                        <div id="copieddata" className="ui black button" onClick={() => CopyData(key)}>
                          Copy Data
                        </div>
                        <div className="ui basic button" onClick={() => localStorage.removeItem(key)}>
                          <i className="trash icon"></i>Delete
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="ui centered card profile">
        <div className="image">
          <img src="assets/marquee/Group1.png" alt="banner" />
        </div>
        <img className="ui tiny circular image" src="assets/marquee/profile.png" alt="profile" />
        <div className="content">
          <p className="header">Roy</p>
          <div className="meta">
            <span className="date">Programmer</span>
          </div>
          <div className="description position-relative">
            <div className="location">
              Land of Enchantment, NM <br></br>
              UNM
            </div>
          </div>
        </div>
        <div className="extra content">
          <span className="ui grey circular label">SEO</span>
          <span className="ui grey circular label">App Development</span>
          <span className="ui grey circular label">E-commerce</span>
        </div>
      </div>
      <p className="text-center">{time.toLocaleString()}<br></br><i className="cog icon" onClick={()=> document.getElementById("settings").classList.add("show")}></i></p>
      <div className="ui centered card options">
        <div className="ui middle aligned divided list">
          <div className="item pe-on rc-drops" onClick={() => OpenCollapse("1")}>
            <div className="right floated content">{open["1"] ? <i className="angle down icon"></i> : <i className="angle right icon"></i>}</div>
            <div className="content">
              <div className="header">Portfolio</div>
            </div>
          </div>
          <Collapse in={open["1"]}>
            <div className="ui middle aligned divided list">
              {[
                { link: "/Exercise", title: "BrickXBrick Fitness ©" },
                { link: "/Movies", title: "Movies" },
                { link: "/Articles", title: "Blog" },
                { link: "/Services", title: "Music" },
              ].map((key, idx) => {
                return (
                  <> {key.link === "/Exercise" && <>
                  <h1 className="text-center hdr">Brick x Brick</h1>
                  <h5 className="text-center sub-hdr">Captial Gains</h5></>}
                  <div key={idx} className="item pe-on rc-drops" onClick={() => goTo(key.link)}>
                    <div className="right floated content">
                      <i className="angle right icon"></i>
                    </div>
                    <div className="content">
                      <div className="header">{key.title}</div>
                    </div>
                  </div></>
                );
              })}
            </div>
          </Collapse>
          <div className="item pe-on rc-drops" onClick={() => OpenCollapse("2")}>
          <div className="right floated content">{open["2"] ? <i className="angle down icon"></i> : <i className="angle right icon"></i>}</div>

            <div className="content">
              <div className="header">News From Around The World</div>
            </div>
          </div>
          <Collapse in={open["2"]}>
            <div className="ui middle aligned divided list">
              {[
                { link: "https://mexiconewsdaily.com/", title: "Mexico News Daily", country: "Mexico", rss: "" },
                { link: "https://www.cbc.ca/news/", title: "Canadian Broadcasting Corporation", country: "Canada", rss: "https://www.cbc.ca/webfeed/rss/rss-topstories?format=json" },
                { link: "https://www.bbc.com/news/", title: "British Broadcasting Corporation", country: "Britain", rss: "https://feeds.bbci.co.uk/news/rss.xml" },
                { link: "https://www.themoscowtimes.com/", title: "The Moscow Times - Independent News From Russia", country: "Russia", rss: "https://www.themoscowtimes.com/rss/news" },
                { link: "https://www.scmp.com/news/china/", title: "News - South China Morning Post", country: "China", rss: "https://www.scmp.com/rss/91/feed" },
                { link: "https://timesofindia.indiatimes.com/", title: "Times of India", country: "India", rss: "https://timesofindia.indiatimes.com/rssfeedstopstories.cms" },
                {
                  link: "https://www.tiktok.com/t/ZT2fNtnmo/",
                  title: "TikTok Rewards",
                  country: "China",
                  rss: "https://www.tiktok.com/ug/incentive/share/tts_coupon_referral?__status_bar=true&hide_nav_bar=1&should_full_screen=1&sharer_biz=ug_paid_acquisition&_pia_=1&aid=1233&region=us&share_time=1740007603666&_d=eil2kb3g6e3cmj&sharer_os=ios&u_code=NzA5NDUyMjQ2MjI5NzM1OTQwNg%3D%3D&et_campaign=tt_referral&et_gameplay=tts_coupon_referral&og_image=https://p16-ug-incentive-va.tiktokcdn.com/tos-maliva-i-68e3t9dfc1-us/9bc07218dcfe482784195339083d5095.png~tplv-68e3t9dfc1-image.image&utm_campaign=client_share&utm_source=copy&share_app_id=1233",
                },
              ].map((key, idx) => {
                return (
                  <div key={idx} className="item pe-on rc-drops" onClick={() => goTo(key.link)}>
                    <div className="right floated content">
                      <i className="angle right icon"></i>
                    </div>
                    <div className="content">
                      <div className="header">{key.title}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </Collapse>
        </div>
      </div>
      <div className="marquee cpimg mb-3" style={{ display: "none" }}>
        <div role="button" className="marquee-prev" title="marquee prev" onClick={handlePrev}>
          <i className="chevron right icon"></i>
        </div>
        <div className="marquee-slider" style={sliderStyle} ref={sliderRef}>
          <div className="marquee-wrapper" style={{ width: `${windowWidth}px` }}>
            <img src="assets/marquee/IMG_9882.jpg" alt="Sky 3" />
            <div className="marquee-text">
              <h1>Exercise Today!</h1>
              <p>Find your next workout from a collection of over 800 exercises.</p>
              <button className="ui basic button" onClick={() => goTo("/Exercise")}>
                <i className="eye icon"></i>Explore
              </button>
            </div>
          </div>
          <div className="marquee-wrapper" style={{ width: `${windowWidth}px` }}>
            <img src="assets/marquee/Sky1.jpg" alt="Sky 1" />
            <div className="marquee-text">
              <h1>Find Your next watch today!</h1>
              <p>explore over 8 thousand movies.</p>
              <button className="ui basic button" onClick={() => goTo("/Movies")}>
                <i className="eye icon"></i>Explore
              </button>
            </div>
          </div>
          <div className="marquee-wrapper" style={{ width: `${windowWidth}px` }}>
            <img src="assets/marquee/Sky2.jpg" alt="Sky 2" />
            <div className="marquee-text">
              <h1>Music Collection.</h1>
              <p>the music database lets users sort and explore my music by genre and audio features.</p>
              <button className="ui basic button" onClick={() => goTo("/Services")}>
                <i className="eye icon"></i>Explore
              </button>
            </div>
          </div>
          <div className="marquee-wrapper" style={{ width: `${windowWidth}px` }}>
            <img src="assets/marquee/Sky3.jpg" alt="Sky 3" />
            <div className="marquee-text">
              <h1>Articles</h1>
              <p>Explore a collection of articles from around my world.</p>
              <button className="ui basic button" onClick={() => goTo("/Articles")}>
                <i className="eye icon"></i>Explore
              </button>
            </div>
          </div>
        </div>
        <div role="button" className="marquee-next" title="marquee next" onClick={handleNext}>
          <i className="chevron left icon"></i>
        </div>
        <ul className="marquee-dots">
          {[...Array(totalSlides)].map((_, index) => (
            <li key={index}>
              <button onClick={() => handleDotClick(index)} className={`${index === currentIndex ? "dots-active" : ""}`}>
                {index + 1}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
export default Home;
