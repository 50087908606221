import React, { useEffect, useRef } from "react";
import "../Exercise/Activity.css";
const ActivityRing = ({ value, limit, clss }) => {
  const circleRef = useRef(null);
  const dotRef = useRef(null);
  useEffect(() => {
    function setProgress(circle, dot) {
      const length = Math.floor(circle.getTotalLength());
      const percent = Math.min(Math.floor((value / limit) * 100), 100);
      circle.style.strokeDashoffset = length;
      circle.style.strokeDasharray = `${length} ${length}`;
      if (percent <= 100) {
        const offset = length - (percent / 100) * length;
        circle.style.strokeDashoffset = offset;
      } else {
        circle.style.strokeDashoffset = 0;
      }
      dot.style.transform = `rotate(${percent * 3.6}deg)`;
    }
    const circle = circleRef.current;
    const dot = dotRef.current;
    if (circle && circle.getBBox().width > 0) {
      setProgress(circle, dot);
    } else {
      const retryTimeout = setTimeout(() => {
        if (circle && circle.getBBox().width > 0) {
          setProgress(circle, dot);
        } else {
          console.warn("SVG dimensions still not ready after retry.");
        }
      }, 2000);
  
      return () => clearTimeout(retryTimeout);
    }
  }, [value, limit]);
  return (
    <div className={clss}>
    <div className="circles-container">
      <div className="stand ring">
        <div className="dot" ref={dotRef}></div>
        <svg className="progress-ring" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet">
          <defs>
            <linearGradient id="sGradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="#15C2E0" />
              <stop offset="100%" stopColor="#1EF8D5" />
            </linearGradient>
          </defs>
          <circle className="progress-ring__circle" ref={circleRef} strokeWidth="33.75" strokeLinecap="round" stroke="url(#sGradient)" fill="transparent" r="33" cx="50" cy="50" />
        </svg>
      </div>
    </div>
    </div>
  );
};

export default ActivityRing;
